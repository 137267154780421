import axios from 'axios'

export default {
  async fetchAssets({
    page = 1, perPage = 25, search = '', filters = []
  } = {}) {
    const response = await axios.get(`${window.location.origin}/user_media_folder/assets.json`, {
      params: {
        search,
        page,
        per_page: perPage,
        ...(filters.length > 0 && { filters: filters.join(',') })
      }
    })

    return {
      assets: response.data,
      total_pages: parseInt(response.headers['x-total-pages'], 10),
      total_count: parseInt(response.headers['x-total-count'], 10)
    }
  }
}
