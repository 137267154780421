<template>
  <div @dragenter="dragover" @dragleave.self="dragleave" class="medialibrary__sidebar__dropzone">

    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file"
      accept="image/*, video/*" style="display: none;" />

    <div class="studio__container-dropzone" v-if="is_hovering" @drop.prevent="drop" @dragenter.prevent
      @dragover.prevent>
      <div class="studio__dropzone-arrows studio__dropzone-arrows--top">
        <i class="fa-solid fa-arrow-down-right"></i>
        <i class="fa-solid fa-arrow-down-left"></i>
      </div>
      <h2 class="huge">Drop files anywhere</h2>
      <div class="studio__dropzone-arrows studio__dropzone-arrows--bottom">
        <i class="fa-solid fa-arrow-up-right"></i>
        <i class="fa-solid fa-arrow-up-left"></i>
      </div>
    </div>

    <div style="flex: 1; width: 100%; height: 100%;">
      <slot v-bind:open_file_input="() => click_input()">
        Dropzone content
      </slot>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    feed: {
      type: Object, required: false, default: () => {
        return { data: [] }
      }
    },
    busy: { type: Boolean, required: false, default: false },
    on_change: { type: Function, required: false, default: (footage) => { } },
  },
  data() {
    return {
      rows: [],
      filelist: [],
      is_hovering: false
    }
  },
  watch: {
    feed: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.rows = newVal.data.map(d => d.data)
      }
    }
  },
  methods: {
    click_input() {
      this.$refs.file.click()
    },
    get_url(file) {
      return URL.createObjectURL(file)
    },
    onChange() {
      this.on_change([...this.$refs.file.files].filter(x => !this.filelist.includes(x)))
      this.filelist = [...this.filelist, ...this.$refs.file.files]
    },
    remove(row, i) {
      this.filelist.splice(i, 1);
      this.on_remove(row)
    },
    dragover(event) {
      event.preventDefault()

      if (!this.is_hovering && Object.values(event.dataTransfer.items).filter(i => i.kind == 'file').length > 0)
        this.is_hovering = true
    },
    dragleave(event) {
      this.is_hovering = false
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.is_hovering = false
    },
  }
}
</script>
