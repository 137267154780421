<template>
    <div class="medialibrary__asset" :class="{ 'medialibrary__asset--selected': isSelected }"
        v-on:click="$emit('select', asset)">

        <!-- Images -->
        <span v-if="asset.content_type == 'image'">
            <img :src="asset.file_url" :id="`asset_${asset.sid}`" v-if="asset.content_type == 'image'" loading="lazy">
            <div v-if="hasFocusPoints" class="medialibrary__asset-focus-label">Focussed</div>
            <div v-if="asset.processing" class="medialibrary__loader-holder">
                {{ asset.msg }} <div class="loader"></div>
            </div>
        </span>

        <!-- Videos -->
        <span v-if="asset.content_type == 'video'">
            <Video :url="asset.file_url" crossorigin="anonymous" :poster="asset.thumbnail_url"
                :load-on-visible="true" />
            <div class="medialibrary__asset-focus-label" v-if="hasScenes">
                {{ asset.feed_element['scenes'].length }} clips</div>
            <div v-if="asset.content_type == 'video'" class="medialibrary__vid-icon">
                <i class="fa-regular fa-video"></i>
            </div>
            <div v-if="asset.processing" class="medialibrary__loader-holder">
                {{ asset.msg }} <div class="loader"></div>
            </div>
        </span>
    </div>
</template>

<script>
import Video from '../video.vue'

export default {
    name: 'AssetItem',

    components: {
        Video
    },

    props: {
        asset: {
            type: Object,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasFocusPoints() {
            return !!this.asset.feed_element['focus']
        },

        hasScenes() {
            return this.asset.feed_element['scenes']?.length > 1
        }
    }
}
</script>