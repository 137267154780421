<template>
    <div class="media_lib__body">
        <div class="media_lib__body__navbar">
            <div class="media_lib__body__navbar__labels">
                <input type="text" placeholder="Search Pexels..." v-model="searchQuery" @input="debouncedSearch">
            </div>
        </div>

        <div class="medialibrary__category-container">
            <!-- Loading states -->
            <div v-if="loading && !photos.length" class="medialibrary__loading">
                <div class="loader"></div>
                <p>Searching Pexels...</p>
            </div>

            <!-- Photos grid -->
            <div v-if="photos.length" class="medialibrary__grid">
                <div v-for="photo in photos" :key="photo.id" class="medialibrary__asset-container">
                    <div class="medialibrary__asset">
                        <img :src="photo.src.medium" :alt="photo.photographer" loading="lazy">

                        <div class="medialibrary__asset-buttons">
                            <button class="button button--light" v-on:click="selectPhoto(photo)">
                                Use
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Infinite scroll trigger -->
            <div ref="loadMoreTrigger" v-if="hasMore && photos.length > 0" class="medialibrary__load-more">
                <div v-if="loadingMore" class="loader loader--small"></div>
            </div>

            <!-- Empty state -->
            <div v-if="!loading && !photos.length" class="medialibrary__empty-state">
                <div class="medialibrary__dropzone-message">
                    <i class="fa-regular fa-image"></i>
                    <h3>Search for photos on Pexels</h3>
                    <p>Type something in the search box above</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'debounce'
import pexelsApi from '../../services/pexels-api'

export default {
    name: 'PexelsTab',

    data() {
        return {
            photos: [],
            loading: false,
            loadingMore: false,
            searchQuery: '',
            page: 1,
            hasMore: true,
            totalResults: 0,
            isVisible: true // Track component visibility
        }
    },

    methods: {
        debouncedSearch: debounce(function () {
            this.page = 1
            this.photos = []
            this.hasMore = true
            this.searchPhotos()
        }, 500),

        async searchPhotos() {
            if (this.loading) return

            const isInitialLoad = this.page === 1
            if (isInitialLoad) {
                this.loading = true
            } else {
                this.loadingMore = true
            }

            try {
                const result = await pexelsApi.searchPhotos({
                    query: this.searchQuery,
                    page: this.page
                })

                if (this.page === 1) {
                    this.photos = result.photos
                } else {
                    this.photos = [...this.photos, ...result.photos]
                }

                this.hasMore = !!result.next_page
                this.totalResults = result.total_results

                // Re-observe after content changes
                this.$nextTick(() => {
                    this.setupInfiniteScroll()
                })
            } catch (error) {
                console.error('Error searching Pexels:', error)
            } finally {
                this.loading = false
                this.loadingMore = false
            }
        },

        async loadMore() {
            if (this.loadingMore || !this.hasMore) return

            this.page += 1
            await this.searchPhotos()
        },

        setupInfiniteScroll() {
            // Clean up any existing observer
            if (this._observer) {
                this._observer.disconnect()
                this._observer = null
            }

            // Only set up observer if we have more photos to load and component is visible
            if (!this.isVisible || !this.hasMore) return

            this._observer = new IntersectionObserver(
                (entries) => {
                    const target = entries[0]
                    if (target.isIntersecting && !this.loadingMore && this.hasMore) {
                        this.loadMore()
                    }
                },
                {
                    root: null,
                    rootMargin: '100px',
                    threshold: 0.1
                }
            )

            this.$nextTick(() => {
                const trigger = this.$refs.loadMoreTrigger
                if (trigger) {
                    this._observer.observe(trigger)

                    // Check if trigger is already visible without scrolling
                    const rect = trigger.getBoundingClientRect()
                    const isVisible = (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    )

                    // If trigger is visible and we have more photos, load them
                    if (isVisible && this.hasMore && !this.loadingMore && this.isVisible) {
                        this.loadMore()
                    }
                }
            })
        },

        selectPhoto(photo) {
            this.$emit('select', {
                title: `Photo by ${photo.photographer}`,
                asset_url: photo.src.large2x,
                thumbnail_url: photo.src.medium,
                attribution: {
                    name: photo.photographer,
                    url: photo.photographer_url
                },
                source: 'pexels'
            })
        }
    },

    mounted() {
        this.isVisible = true
        this.searchPhotos() // Load initial curated photos
        this.setupInfiniteScroll()
    },

    beforeDestroy() {
        this.isVisible = false
        if (this._observer) {
            this._observer.disconnect()
            this._observer = null
        }
    }
}
</script>