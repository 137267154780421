import _ from 'lodash'
import Layer from './model'

export const autoScaleFontSize = (layer, text = null, scale = 1.1) => {
  if (text === null) {
    text = layer.get_text();
  }

  function measureText(text, fontSize, textareaWidth, fontFamily = 'Arial', fontWeight = 'normal') {
    // Create a temporary canvas for more accurate text measurement
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Create a temporary div for line wrapping measurement
    const textSizer = document.createElement('div');
    textSizer.style.cssText = `
      visibility: hidden;
      position: absolute;
      font-family: ${fontFamily};
      font-size: ${fontSize}px;
      font-weight: ${fontWeight};
      width: ${textareaWidth}px;
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: normal;
      letter-spacing: normal;
    `;
    textSizer.textContent = text;

    // Append and measure
    document.body.appendChild(textSizer);
    const textHeight = textSizer.offsetHeight;
    document.body.removeChild(textSizer);

    // Get the width of the longest word for min font size calculation
    ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const words = text.split(/\s+/);
    const longestWordWidth = Math.max(...words.map((word) => ctx.measureText(word).width));

    return {
      height: textHeight,
      longestWordWidth
    };
  }

  function findOptimalFontSize(layer, text) {
    const minFontSize = 8;
    const maxFontSize = 200;
    const tolerance = 0.5; // Tolerance for binary search

    const containerWidth = layer.get_text_width_constraint();
    const containerHeight = layer.height;

    // Get font settings from layer
    const fontFamily = layer.config.font_family || 'Arial';
    const fontWeight = layer.config.font_weight || 'normal';

    let low = minFontSize;
    let high = maxFontSize;

    while (high - low > tolerance) {
      const mid = (low + high) / 2;
      const { height, longestWordWidth } = measureText(
        text,
        mid,
        containerWidth,
        fontFamily,
        fontWeight
      );

      // Check if text fits within constraints
      const fitsHeight = height <= containerHeight;
      const fitsWidth = longestWordWidth <= containerWidth;

      if (fitsHeight && fitsWidth) {
        low = mid;
      } else {
        high = mid;
      }
    }

    // Apply scaling factor and round to nearest 0.5
    const optimalSize = Math.floor((low * scale) * 2) / 2;

    // Ensure minimum readable size
    return Math.max(minFontSize, optimalSize);
  }

  // Cache the result to prevent unnecessary recalculations
  if (!layer.cachedFontSize || layer.cachedText !== text) {
    layer.cachedFontSize = findOptimalFontSize(layer, text);
    layer.cachedText = text;
  }

  return layer.cachedFontSize;
}

export const getGroupedLayers = (layers, layer) => {
  const groupedLayers = layers.filter((l) => l.config.group_id === layer.id);
  let allGroupedLayers = [...groupedLayers];

  groupedLayers.forEach((groupedLayer) => {
    allGroupedLayers = allGroupedLayers.concat(getGroupedLayers(layers, groupedLayer));
  });

  return allGroupedLayers;
};

export const getLinkedLayers = (layers, layer) => {
  const linked_layers = layers.filter((l) => {
    const is_sibling = l.parent_id && layer.parent_id && l.parent_id === layer.parent_id
    const incoming_is_parent = l.parent_id && l.parent_id === layer.id
    const incoming_is_child = layer.parent_id && l.id === layer.parent_id
    return is_sibling || incoming_is_parent || incoming_is_child
  })

  // Add the layer itself if it is a parent
  if (!layer.parent_id) {
    linked_layers.push(layer)
  }

  return linked_layers
}

export const getTransformValue = (axis, layer) => {
  const transform = layer.config.transform || 'translate(0px, 0px)';
  const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/);
  if (match) {
    return Math.round(axis === 'x' ? parseFloat(match[1]) : parseFloat(match[2]));
  }
  return 0;
}

// Add master size configuration at the top of the file
export const MASTER_SIZE = {
  name: 'facebook_square',
  width: 1080,
  height: 1080
};

export const layerBelongsToMasterSize = (layer, variants = []) => {
  const variant = variants.find((v) => v.id === layer.image_canvas_variant_id);
  return variant ? isMasterSize(variant) : false;
};

// Helper function to check if a variant is the master size
export const isMasterSize = (variant) => variant.size.display_dimensions[0] === MASTER_SIZE.width
  && variant.size.display_dimensions[1] === MASTER_SIZE.height;

// Add helper functions for rotation calculations
const decomposeTransform = (transform) => {
  const defaults = { x: 0, y: 0, rotate: 0 };
  if (!transform) return defaults;

  const translateMatch = transform.match(/translate\(([-\d.]+)px,\s*([-\d.]+)px\)/);
  const rotateMatch = transform.match(/rotate\(([-\d.]+)deg\)/);

  return {
    x: translateMatch ? parseFloat(translateMatch[1]) : 0,
    y: translateMatch ? parseFloat(translateMatch[2]) : 0,
    rotate: rotateMatch ? parseFloat(rotateMatch[1]) : 0
  };
};

const calculateRotatedPosition = (x, y, angle, originX, originY) => {
  // Convert angle to radians
  const rad = (angle * Math.PI) / 180;

  // Translate point to origin
  const dx = x - originX;
  const dy = y - originY;

  // Rotate point
  const rotatedX = dx * Math.cos(rad) - dy * Math.sin(rad);
  const rotatedY = dx * Math.sin(rad) + dy * Math.cos(rad);

  // Translate back
  return {
    x: rotatedX + originX,
    y: rotatedY + originY
  };
};

// Helper function to update transform configuration for the linked layer
const updateTransformConfig = (originLayer, siblingLayer, originVariant, targetVariant, isFromMasterSize, isTargetMaster, conf) => {
  if (isTargetMaster && siblingLayer.is_unlinked(Layer.LINK_POSITION_DIMENSIONS)) {
    delete conf.transform;
    return conf;
  } if (isFromMasterSize && !isTargetMaster) {
    return processMasterToNonMasterTransform(originLayer, siblingLayer, originVariant, targetVariant, conf);
  } if (!siblingLayer.is_unlinked(Layer.LINK_POSITION_DIMENSIONS)) {
    const originTransform = decomposeTransform(originLayer.config.transform);
    const targetTransform = decomposeTransform(siblingLayer.config.transform);
    conf.transform = `translate(${targetTransform.x}px, ${targetTransform.y}px) rotate(${originTransform.rotate}deg)`;
  } else {
    delete conf.transform;
  }
  return conf;
};

// Helper function for master-to-non-master transform adjustments
const processMasterToNonMasterTransform = (originLayer, siblingLayer, originVariant, targetVariant, conf) => {
  const positionLinkEnabled = !siblingLayer.is_unlinked(Layer.LINK_POSITION_DIMENSIONS)
    && !originLayer.is_unlinked(Layer.LINK_POSITION_DIMENSIONS);
  if (!positionLinkEnabled) {
    delete conf.transform;
    return conf;
  }

  const originTransform = decomposeTransform(originLayer.config.transform);
  const scaleX = targetVariant.size.display_dimensions[0] / originVariant.size.display_dimensions[0];
  const scaleY = targetVariant.size.display_dimensions[1] / originVariant.size.display_dimensions[1];
  const originCenterX = originLayer.width / 2;
  const originCenterY = originLayer.height / 2;

  const scaledPos = calculateRotatedPosition(
    originTransform.x * scaleX,
    originTransform.y * scaleY,
    originTransform.rotate,
    originCenterX * scaleX,
    originCenterY * scaleY
  );

  conf.transform = `translate(${scaledPos.x}px, ${scaledPos.y}px) rotate(${originTransform.rotate}deg)`;
  siblingLayer.position = originLayer.position;
  if (originLayer.text !== undefined) {
    siblingLayer.text = originLayer.text;
  }

  const originDims = originVariant.size.display_dimensions;
  const targetDims = targetVariant.size.display_dimensions;

  // Check if master layer exceeds canvas dimensions
  if (originLayer.width >= originDims[0] && originLayer.height >= originDims[1]) {
    // If layer exceeds both dimensions, set to target dimensions
    siblingLayer.width = targetDims[0];
    siblingLayer.height = targetDims[1];
  } else if (originLayer.height >= originDims[1] || originLayer.width >= originDims[0]) {
    // Calculate scales based on both dimensions
    const heightScale = targetDims[1] / originLayer.height;
    const widthScale = targetDims[0] / originLayer.width;
    const scale = Math.min(heightScale, widthScale);

    // If height is the constraining factor
    if (originLayer.height >= originDims[1]) {
      siblingLayer.height = targetDims[1];
      siblingLayer.width = Math.min(
        Math.round(originLayer.width * scale),
        targetDims[0]
      );
    }
    // If width is the constraining factor
    if (originLayer.width >= originDims[0]) {
      siblingLayer.width = targetDims[0];
      siblingLayer.height = Math.min(
        Math.round(originLayer.height * scale),
        targetDims[1]
      );
    }
  } else {
    if (siblingLayer.width && siblingLayer.height) {
      const compositionSize = Math.min(targetDims[0], targetDims[1]);
      const masterSize = Math.min(originDims[0], originDims[1]);
      const compositionScale = compositionSize / masterSize;
      siblingLayer.width = Math.round(originLayer.width * compositionScale);
      siblingLayer.height = Math.round(originLayer.height * compositionScale);
    }
    if (originLayer.config.transform) {
      const [originalX, originalY] = originLayer.config.transform
        .match(/translate\(([^,]+),\s*([^)]+)\)/)
        .slice(1)
        .map((val) => parseFloat(val));

      const compositionScale = Math.min(
        targetDims[0] / originDims[0],
        targetDims[1] / originDims[1]
      );
      const xOffset = (targetDims[0] - (originDims[0] * compositionScale)) / 2;
      const yOffset = (targetDims[1] - (originDims[1] * compositionScale)) / 2;
      const scaledX = (originalX * compositionScale) + xOffset;
      const scaledY = (originalY * compositionScale) + yOffset;

      conf.transform = `translate(${scaledX}px, ${scaledY}px) rotate(${originTransform.rotate}deg)`;
    }
  }
  return conf;
};

// Helper function to sync style properties from origin to sibling
const syncStyleProperties = (originLayer, siblingLayer, isTargetMaster) => {
  const propertyLinkMap = {
    color: Layer.LINK_TEXT_COLOR,
    background: Layer.LINK_BACKGROUND,
    align: Layer.LINK_ALIGNMENT,
    fontSize: Layer.LINK_FONT_SIZE
  };

  Object.entries(propertyLinkMap).forEach(([prop, linkType]) => {
    // Skip if target is master and property is unlinked
    if (isTargetMaster && siblingLayer.is_unlinked(linkType)) return;

    // For fontSize specifically, check if either layer has unlinked font size
    if (prop === 'fontSize' && (
      originLayer.is_unlinked(Layer.LINK_FONT_SIZE)
      || siblingLayer.is_unlinked(Layer.LINK_FONT_SIZE)
    )) {
      return;
    }

    // Sync property if it exists and isn't unlinked
    if (originLayer[prop] !== undefined && !siblingLayer.is_unlinked(linkType)) {
      siblingLayer[prop] = originLayer[prop];
    }
  });
};

// Helper function to sync component-specific properties
const syncComponentProperties = (originLayer, siblingLayer, conf) => {
  if (siblingLayer.layer_type === 'component' && conf.component) {
    const componentType = conf.component.type;
    Object.keys(conf[componentType]).forEach((key) => {
      if (originLayer.is_unlinked(`component.${key}`) || siblingLayer.is_unlinked(`component.${key}`)) {
        delete conf[componentType][key];
      }
    });
    conf[componentType] = { ...siblingLayer.config[componentType], ...conf[componentType] };
  }
  return conf;
};

// Helper to sync adjustments (opacity, border_radius)
const syncAdjustments = (originLayer, siblingLayer, conf) => {
  if (!siblingLayer.is_unlinked(Layer.LINK_ADJUSTMENTS)) {
    conf.opacity = originLayer.config.opacity;
    conf.border_radius = originLayer.config.border_radius;
  }
  return conf;
};

// Helper to sync additional properties like shadows, padding, highlights, and animations
const syncAdditionalProperties = (originLayer, siblingLayer, conf) => {
  ['LINK_SHADOWS', 'LINK_PADDING', 'LINK_HIGHLIGHTS', 'LINK_ANIMATIONS'].forEach((linkType) => {
    if (!siblingLayer.is_unlinked(Layer[linkType])) {
      switch (linkType) {
        case 'LINK_SHADOWS':
          conf.dropshadows = originLayer.config.dropshadows;
          break;
        case 'LINK_PADDING':
          ['top', 'bottom', 'left', 'right'].forEach((side) => {
            conf[`padding_${side}`] = originLayer.config[`padding_${side}`];
          });
          break;
        case 'LINK_HIGHLIGHTS':
          conf.highlights = originLayer.config.highlights;
          break;
        case 'LINK_ANIMATIONS':
          conf.animations = originLayer.config.animations;
          conf.animation = originLayer.config.animation;
          break;
        default:
          break;
      }
    }
  });
  return conf;
};

export const updateLinkedLayers = (siblingLayers, originLayer, variants = []) => {
  // Clone input layers into new Layer instances
  siblingLayers = siblingLayers.map((siblingLayer) => new Layer({ ...siblingLayer }));
  originLayer = new Layer({ ...originLayer });

  const originVariant = variants.find((v) => v.id === originLayer.image_canvas_variant_id);
  const linkedLayers = getLinkedLayers(siblingLayers, originLayer);
  const isFromMasterSize = layerBelongsToMasterSize(originLayer, variants);

  const updatedLinkedLayers = linkedLayers.map((siblingLayer) => {
    const targetVariant = variants.find((v) => v.id === siblingLayer.image_canvas_variant_id);
    const isTargetMaster = isMasterSize(targetVariant);

    // Start with a clone of the origin config
    let conf = _.cloneDeep(originLayer.config);

    // Update transform configuration using helper function
    conf = updateTransformConfig(originLayer, siblingLayer, originVariant, targetVariant, isFromMasterSize, isTargetMaster, conf);

    // Sync style properties from origin to sibling
    syncStyleProperties(originLayer, siblingLayer, isTargetMaster);

    // Sync component-specific properties
    conf = syncComponentProperties(originLayer, siblingLayer, conf);

    // Sync adjustments (opacity, border radius)
    conf = syncAdjustments(originLayer, siblingLayer, conf);

    // Sync additional properties (shadows, padding, etc.)
    conf = syncAdditionalProperties(originLayer, siblingLayer, conf);

    // Ensure position is a valid number
    siblingLayer.position = Math.max(1, parseInt(siblingLayer.position, 10) || 1);
    siblingLayer.config = { ...siblingLayer.config, ...conf };

    return new Layer({ ...siblingLayer });
  });

  // Sort layers by position before returning
  const sortedLayers = updatedLinkedLayers.sort((a, b) => a.position - b.position);

  return {
    linked_layers: linkedLayers,
    updated_linked_layers: sortedLayers
  };
};
