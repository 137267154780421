<template>
    <div class="media_lib__body__navbar__labels" v-if="showFilters">
        <div class="button__toggle__light" v-on:click="$emit('clear-filters')"
            :class="{ 'button__toggle--active': filters.length == 0 }">
            All
        </div>
        <div class="button__toggle__light" :class="{ 'button__toggle--active': filters.includes(label) }"
            v-on:click="$emit('toggle-filter', label)" v-for="label in filteredLabels" :key="label">
            {{ label }} ({{ labels[label] }})
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterBar',

    props: {
        filters: {
            type: Array,
            default: () => []
        },
        labels: {
            type: Object,
            required: true
        },
        showFilters: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        filteredLabels() {
            return Object.keys(this.labels).filter(label => this.labels[label] > 0);
        }
    }
}
</script>