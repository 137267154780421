<template>
    <div class="medialibrary__grid">
        <div v-for="(asset, index) in assets" :key="`asset_${index}_${asset.id}`" class="medialibrary__asset-container">

            <AssetItem :asset="asset" :is-selected="isSelected(asset)" @select="onSelect" />

            <div class="medialibrary__asset-buttons">
            </div>

            <AssetActions :asset="asset" v-on="$listeners" />
        </div>
    </div>
</template>

<script>
import AssetItem from './asset-item.vue'
import AssetActions from './asset-actions.vue'

export default {
    name: 'AssetGrid',

    components: {
        AssetItem,
        AssetActions
    },

    props: {
        assets: {
            type: Array,
            required: true
        },
        selectedAssets: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        isSelected(asset) {
            return this.selectedAssets.find(a => a.id === asset.id)
        },

        onSelect(asset) {
            if (!asset.processing) {
                this.$emit('select', asset)
            }
        }
    }
}
</script>