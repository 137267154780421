import axios from 'axios'

export default {
  async searchPhotos({ query = '', page = 1, perPage = 25 } = {}) {
    const response = await axios.get('/api/v1/pexels/search', {
      params: {
        ...(query && { query }),
        page,
        per_page: perPage,
        size: 'large'
      }
    })

    return {
      photos: response.data.photos,
      total_results: response.data.total_results,
      next_page: response.data.next_page
    }
  }
}
