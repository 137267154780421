<template>
    <div 
        class="callout__container" 
        :class="calloutClass" 
        :style="calloutStyles"
    >
        <div class="callout__content">
            <div :style="`width:${100 - line_length}%;`"></div>
            <div class="callout__text" :style="`color: ${layer.color}; font-size: ${layer.fontSize * .45}px; font-family: ${font}`">
                {{ layer.text }}
            </div>
        </div>
        <div class="callout__line">
            <div v-if="layer.config.callout.show_bullet" class="callout__dot" :style="`background: ${line_color};`">
            </div>
            <div class="callout__line_border" :style="`border-color: ${line_color};`"></div>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'

export default {
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],
  data() {
    return {}
  },
  computed: {
    line_length() {
      return this.layer.config.callout.line_length || 60
    },
    alignment() {
      return this.layer.config.callout.align || 'left'
    },
    line_align() {
      return this.layer.config.callout.line_align || 'bottom'
    },
    line_color() {
      return this.layer.config.callout.line_color || 'white'
    },
    show_bullet() {
      return this.layer.config.callout.show_bullet || 'true'
    },
    calloutClass() {
      let secondPart = ''
      if (this.line_align === 'normal') {
        if (this.alignment === 'left' || this.alignment === 'right') {
          secondPart = 'bottom'
        } else if (this.alignment === 'top' || this.alignment === 'bottom') {
          secondPart = 'left'
        }
      } else if (this.line_align === 'invert') {
        if (this.alignment === 'left' || this.alignment === 'right') {
          secondPart = 'top'
        } else if (this.alignment === 'top' || this.alignment === 'bottom') {
          secondPart = 'right'
        }
      }
      return `callout--${this.alignment}--${secondPart}`
    },

    // Nieuw: Dynamisch style object
    calloutStyles() {
      const styles = {
        color: this.layer.color,
        width: this.layer.width + 'px',
      }

      // Alleen height toevoegen voor top of bottom
      if (this.alignment === 'top' || this.alignment === 'bottom') {
        styles.height = this.layer.height + 'px'
      }

      return styles
    }
  },
  methods: {
    parse(text) {
      return highlight.highlight(
        text,
        this.layer.config?.highlights?.background_color,
        this.layer.config?.highlights?.text_color
      )
    }
  }
}
</script>