<template>
    <div class="medialibrary__asset-action">
        <Panel :ref="`panel_asset_${asset.id}`" :panelTitle="'Actions'" position="bottom-right" :margin="0">
            <template #button>
                <button class="button button--dark">
                    <i class="fa-solid fa-square-ellipsis-vertical"></i>
                </button>
            </template>

            <button class="button button--light" v-if="showFocusPoint" v-on:click.stop="handleAction('focus-point')">
                <i class="fa-regular fa-arrows-to-circle"></i> Set focuspoint
            </button>

            <button class="button button--light" v-if="showRemoveBackground" @click="handleAction('remove-background')">
                <i class="fa-regular fa-eraser"></i> Remove background
            </button>

            <button class="button button--light" v-if="showSelectScene" v-on:click.stop="handleAction('select-scene')">
                <i class="fa-regular fa-video"></i> Select scene
            </button>

            <button class="button button--light" v-if="!asset.processing" v-on:click="handleAction('delete')"
                @click.stop>
                <i class="fa-regular fa-trash"></i> Delete
            </button>
        </Panel>
    </div>
</template>

<script>
import Panel from '../panel.vue'

export default {
    name: 'AssetActions',

    components: {
        Panel
    },

    props: {
        asset: {
            type: Object,
            required: true
        }
    },

    computed: {
        showFocusPoint() {
            return this.asset.content_type === 'image' && !this.asset.processing
        },

        showRemoveBackground() {
            return this.asset.content_type === 'image' &&
                !this.asset.processing &&
                !this.asset.feed_element['parent_asset_id']
        },

        showSelectScene() {
            return this.asset.content_type === 'video' && !this.asset.processing && this.hasScenes()
        }
    },

    methods: {
        handleAction(action) {
            // Close the panel
            this.$refs[`panel_asset_${this.asset.id}`].closeMenu();

            // Emit the action
            this.$emit(action, this.asset);
        },
        hasScenes() {
            return this.asset.feed_element['scenes']?.length > 1
        }
    }
}
</script>
