<template>
    <div class="media_lib__body__navbar__labels">
        <input type="text" placeholder="Search..." v-model="searchQuery" ref="search"
            @input="$emit('update:searchQuery', $event.target.value)">

        <div style="display: flex; gap: 8px">
            <div class="button button--light" v-on:click="triggerUpload">
                Upload
            </div>
        </div>

        <input type="file" multiple accept="image/*, video/*" style="display: none;" ref="fileInput"
            @change="onFileSelect" />
    </div>
</template>

<script>
export default {
    name: 'UploadSection',

    props: {
        searchQuery: {
            type: String,
            required: true
        }
    },

    methods: {
        triggerUpload() {
            this.$refs.fileInput.click()
        },

        onFileSelect(event) {
            this.$emit('files-selected', [...event.target.files])
        }
    }
}
</script>