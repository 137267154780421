<template>
  <div v-on-clickaway="away">
    <div @click="toggleMenu">
      <slot name="button">
      </slot>
    </div>
    <Teleport to="#teleportTo">
      <div v-show="isMenuVisible" ref="panel"
        :class="['panel_containeraar', { 'panel_containeraar--visible': isMenuVisible }]" :style="menuStyle" @click.stop>
        <div class="panel_containeraar__header">
          <h4>{{ panelTitle }}</h4>
          <button class="button__toggle__backplated" @click="closeMenu">
            <i class="fa-regular fa-xmark"></i>
          </button>
        </div>
        <div class="panel_containeraar__body">
          <slot></slot>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
import Teleport from 'vue2-teleport';

export default {
  name: "Panel",
  components: { Teleport },
  props: {
    position: { type: String, default: 'left' },
    margin: { type: Number, default: 20 },
    panelTitle: { type: String, default: 'Toggle Menu' },
  },
  data() {
    return {
      isMenuVisible: false,
      menuStyle: {
        top: "0px",
        left: "0px",
      }
    };
  },
  mixins: [clickaway],
  methods: {
    toggleMenu(event) {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) {
        this.updateMenuPosition(event);
      }
    },
    closeMenu() {
      this.isMenuVisible = false;
    },
    away() {
      this.isMenuVisible = false;
    },
    showMenu() {
      this.isMenuVisible = true;
    },
    updateMenuPosition(event) {
      const menu = this.$refs.panel;
      const buttonRect = event.target.getBoundingClientRect();
      const margin = parseInt(this.margin) || 0;

      menu.style.display = "block";
      const menuHeight = menu.offsetHeight;
      const menuWidth = menu.offsetWidth;
      menu.style.display = "none";

      let menuTop, menuLeft;

      switch (this.position) {
        case "left":
          menuTop = buttonRect.top + window.scrollY;
          menuLeft = buttonRect.left - menuWidth - margin;
          break;
        case "right":
          menuTop = buttonRect.top + window.scrollY;
          menuLeft = buttonRect.right + margin;
          break;
        case "top":
          menuTop = buttonRect.top + window.scrollY - menuHeight - margin;
          menuLeft = buttonRect.left;
          break;
        case "bottom":
          menuTop = buttonRect.bottom + window.scrollY + margin;
          menuLeft = buttonRect.left;
          break;
        case "left-middle":
          menuTop = buttonRect.top + window.scrollY + (buttonRect.height / 2) - (menuHeight / 2);
          menuLeft = buttonRect.left - menuWidth - margin;
          break;
        case "right-middle":
          menuTop = buttonRect.top + window.scrollY + (buttonRect.height / 2) - (menuHeight / 2);
          menuLeft = buttonRect.right + margin;
          break;
        case "top-middle":
          menuTop = buttonRect.top + window.scrollY - menuHeight - margin;
          menuLeft = buttonRect.left + (buttonRect.width / 2) - (menuWidth / 2);
          break;
        case "bottom-middle":
          menuTop = buttonRect.bottom + window.scrollY + margin;
          menuLeft = buttonRect.left + (buttonRect.width / 2) - (menuWidth / 2);
          break;
        case "bottom-right":
          menuTop = buttonRect.bottom + window.scrollY + margin;
          menuLeft = buttonRect.right - menuWidth;
          break;
        case "bottom-left":
          menuTop = buttonRect.bottom + window.scrollY + margin;
          menuLeft = buttonRect.left;
          break;
        default:
          menuTop = buttonRect.top + window.scrollY;
          menuLeft = buttonRect.left - menuWidth - margin;
      }

      if (menuTop < 0) {
        menuTop = 0;
      }

      if (menuTop + menuHeight > window.innerHeight) {
        menuTop = window.innerHeight - menuHeight;
      }

      if (menuLeft < 0) {
        menuLeft = 0;
      }

      if (menuLeft + menuWidth > window.innerWidth) {
        menuLeft = window.innerWidth - menuWidth;
      }

      this.menuStyle.top = menuTop + "px";
      this.menuStyle.left = menuLeft + "px";
    },
    handleResize() {
      if (this.isMenuVisible) {
        this.updateMenuPosition();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
