<template>
    <div :style="{ color: layer.color, width: layer.width + 'px' }">
        <div class="">
            <div class="works_title"
                :style="`color: ${layer.color}; font-size: ${layer.fontSize * .45}px; background: ${bgColor}; font-family: ${font}`"
                v-html="parsedText"></div>
            <svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg"
                version="1.1">
                <defs>
                    <filter id="instagram">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -8"
                            result="goo" />
                        <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                    </filter>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        line_length() {
            return this.layer.config[this.component_name].line_length || 60
        },
        alignment() {
            return this.layer.config[this.component_name].align || 'left'
        },
        v_align() {
            return this.layer.config[this.component_name].v_align || 'bottom'
        },
        h_align() {
            return this.layer.config[this.component_name].h_align || 'left'
        },
        bgColor() {
            // Dynamically fetch bg_color, default to 'white' if not set
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        parsedText() {
            let text = this.layer.text || ''
            // Onderlijn alleen het deel NA de @
            text = text.replace(/@(\S+)/g, '@<span style="text-decoration: underline;">$1</span>')

            // Eventueel highlight toepassen als nodig:
            text = highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )

            return text
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )
        },
    }
}
</script>