import SalePrice from './pricing/sale_price'
import SalePercentage from './pricing/sale_percentage'
import SaleDifference from './pricing/sale_difference'

import ProductImage from './product/image'
import ProductTitle from './product/title'
import Price from './product/price'
import ProductScore from './product/review'
import AdditionalImages from './product/additional_images'
import ImageWithoutBackground from './product/image_without_background'

import GenericText from './generic/text'
// import GenericGroup from './generic/group'
import GenericTextArea from './generic/text_area'
// import GenericScaledText from './generic/scaled_text'
import GenericImage from './generic/image'
import GenericTitle from './generic/title'
import GenericCTA from './generic/cta'
import GenericSubtitle from './generic/subtitle'
import GenericMedia from './generic/media'

import VideoSwiper from './video/swiper'
// import VideoTexter from './video/texter'
import Background from './video/background'

import BrandkitReviewScore from './brandkit/review_score'
import BrandkitCustomerQuote from './brandkit/customer_quotes'
import BrandkitNumberOfReviews from './brandkit/number_of_reviews'
import BrandkitUSPS from './brandkit/usps'
import BrandkitStars from './brandkit/stars'
// import BrandkitLogo from './brandkit/logo'

import Rectangle from './shapes/rectangle'
import Circle from './shapes/circle'

const predefinedComponents = {}

const requireComponent = require.context(
  './predefined',
  false,
  /[\w-]+\.js$/
)

const requireVideoComponent = require.context(
  './predefined/video',
  false,
  /[\w-]+\.js$/
)

const importComponents = (requireContext) => {
  requireContext.keys().forEach((fileName) => {
    const componentConfig = requireContext(fileName)
    const componentName = fileName
      .replace(/^\.\/(.*)\.\w+$/, '$1')
      .replace(/-([a-z])/g, (g) => g[1].toUpperCase())

    // Check if the component has an enabled method and if it returns true
    const component = componentConfig.default || componentConfig
    if (typeof component.enabled === 'function' && component.enabled()) {
      predefinedComponents[componentName] = component
    }
  })
}

importComponents(requireComponent)
importComponents(requireVideoComponent)

export const predefinedComponentsList = predefinedComponents

const COMPONENTS = [GenericText, GenericImage, Rectangle, Circle, ProductImage,
  ImageWithoutBackground, AdditionalImages, GenericTextArea,
  ProductTitle, ProductScore, Price, SalePrice,
  SaleDifference, SalePercentage, BrandkitReviewScore, BrandkitStars,
  BrandkitNumberOfReviews, BrandkitCustomerQuote, BrandkitUSPS,
  Background, VideoSwiper]

const labelOrder = ['text', 'image', 'background', 'callout', 'grid', 'sticker', 'stack', 'lists', 'native', 'review', 'text', 'overlays']

export default {
  media_components(video) {
    const list = [
      new GenericMedia({ variable_tag: 'product', display_name: 'Media' }),
    ]
    if (video) {
      list.push(new Background({ variable_tag: 'lifestyle' }, {
        show_animations: true,
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          { type: 'inwhileout' }
        ]
      }))
    } else {
      list.push(new Background({ variable_tag: 'lifestyle' }),)
    }
    return list
  },
  shape_components() {
    return [new Rectangle(), new Circle()]
  },
  text_components() {
    return [
      new GenericTitle({ variable_tag: 'title', display_name: 'Title', default_value: 'Title' }),
      new GenericSubtitle({ variable_tag: 'subtitle', display_name: 'Subtitle', default_value: 'Subtitle' }),
      new GenericCTA({ variable_tag: 'CTA', display_name: 'CTA', default_value: 'CTA' }),
      new GenericTextArea({ variable_tag: 'info', display_name: 'Info', default_value: 'info' }),
    ]
  },
  predefined_components() {
    const components = Object.values(predefinedComponents).map((Component) => new Component())

    // Sort components based on their first matching label in labelOrder
    return components.sort((a, b) => {
      const aIndex = Math.min(...a.labels().map((label) => labelOrder.indexOf(label)))
      const bIndex = Math.min(...b.labels().map((label) => labelOrder.indexOf(label)))
      return aIndex - bIndex
    })
  },
  tree(kwargs) {
    const { addons } = kwargs
    const { video } = kwargs
    const tree = {}
    COMPONENTS.forEach((Component) => {
      const c = new Component()
      c.labels().forEach((label) => {
        if (!tree[label]) {
          tree[label] = []
        }
        if (c.required_addon() == null || addons.includes(c.required_addon())) {
          if (video && c.labels().includes('video')) {
            tree[label].push(c)
          } else if (!c.labels().includes('video')) {
            tree[label].push(c)
          }
        }
      })
    })
    Object.keys(tree).forEach((key) => {
      if (tree[key].length === 0) {
        delete tree[key]
      }
    })
    return tree
  }
}
