import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class TextHeaderSubheader extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Text Header Subheader'
  }

  labels() {
    return ['text']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [

        // Rij 2: main text
        [
          {
            type: 'textArea',
            name: 'Header',
            hide_label: false,
            key: 'root.text',
          },
          {
            type: 'color',
            name: 'Color',
            key: 'root.color',
          },
        ],

        // Rij 1: top text
        [
          {
            type: 'textArea',
            name: 'Subheader',
            key: 'top_text',
          },
          {
            type: 'color',
            name: 'Color',
            key: 'top_text_color',
          },
        ],

        // Nieuw: Knoppen voor flex direction

        {
          type: 'buttonGroup',
          name: 'Direction',
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Row',
              icon: 'fa-regular fa-arrow-up', // Een horizontale lijn om 'row' te symboliseren
              value: 'column',
            },
            {
              type: 'icon',
              name: 'Column',
              icon: 'fa-regular fa-arrow-down', // Een verticale lijn om 'column' te symboliseren
              value: 'column-reverse',
            },
          ],
          default: 'column'
        },

        // Nieuw: Slider voor top-tekst-ratio
        {
          type: 'slider',
          name: 'Subheader text ratio (%)',
          key: 'top_text_ratio',
          min: 10,
          max: 100,
          step: 1,
        },

        // Slider voor tekstgrootte
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },

        // Overige opties
        {
          type: 'slider',
          name: 'Distance',
          key: 'gap',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'white',
          config: {
            ...baseConfig.config,
            text_header_subheader: {
              ...baseConfig.config.text_header_subheader,
              top_text_ratio: 42,
              top_text_color: '#D8D8D8',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'left',
      text: 'Sample title',
      width: 500,
      height: 200,
      fontSize: 110,
      background: '#FF4B4B',
      ...this.opts,
      config: {
        line_height: 1,
        v_align: 'center',
        component: {
          type: 'text_header_subheader',
          name: 'Text header subheader',
        },
        text_header_subheader: {
          // Bestaande keys...
          top_text: 'Lorem ipsum dolor sit amet, consectetur.',
          top_text_color: 'grey',
          line_through: false,
          main_bg_color: '#FFFFFF',
          top_bg_color: 'black',
          border_width: 10,
          h_align: 'flex-start',
          shape: 'circle',
          gap: 14,
          direction: 'column',
          top_text_ratio: 42,
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
