<template>
  <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">
    <div :style="`
          display: flex;
          flex-direction: ${layer.config.text_header_subheader.direction};
          z-index: 0;
          gap: ${layer.config.text_header_subheader.gap}px;
        `">

      <!-- Hoofdtekst (nieuwe prijs) -->
      <span v-html="textWithBreaks" :style="{
        color: layer.config.text_header_subheader.main_text_color,
        fontSize: (layer.fontSize * 0.55) + 'px',
        fontFamily: font,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        borderRadius: layer.config.text_header_subheader.bg_radius + 'px',
        display: 'inline-block', /* Zorgt dat de span alleen zo breed is als de content */
      }"></span>

      <!-- Top tekst (oude prijs) -->
      <span v-html="top_textWithBreaks" :style="{
        color: layer.config.text_header_subheader.top_text_color,
        fontSize: (layer.fontSize * 0.55 * topTextRatio) + 'px',
        textDecoration: layer.config.text_header_subheader.line_through ? 'line-through' : 'none',
        fontFamily: font,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        borderRadius: layer.config.text_header_subheader.bg_radius + 'px',
        display: 'inline-block', /* Zorgt dat de span alleen zo breed is als de content */
      }">
      </span>

    </div>
  </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'

export default {
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],
  computed: {
    component_name() {
      return this.layer.config.component.type
    },
    h_align() {
      return this.layer.config[this.component_name].h_align || 'flex-start'
    },
    textWithBreaks() {
      if (!this.layer.text) return ''
      return this.layer.text.replace(/\n/g, '<br>')
    },
    top_textWithBreaks() {
      if (!this.layer.config.text_header_subheader.top_text) {
        return ''
      }
      return this.layer.config.text_header_subheader.top_text.replace(/\n/g, '<br>')
    },
    // Handige computed om rekenfoutjes te voorkomen
    topTextRatio() {
      // Slider is 10..100 => omzetten naar 0.1..1.0
      const ratio = this.layer.config.text_header_subheader.top_text_ratio
      return ratio ? ratio / 100 : 1
    }
  },
  methods: {
    parse(text) {
      return highlight.highlight(
        text,
        this.layer.config?.highlights?.background_color,
        this.layer.config?.highlights?.text_color
      )
    },
  },
}
</script>