var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ color: _vm.layer.color, width: _vm.layer.width + 'px', position: 'relative' })},[_c('div',{style:(("\n        display: inline-flex;\n        flex-direction: " + (_vm.layer.config.sticker_pricetag_basic.direction) + ";\n        z-index: 0;\n        gap: " + (_vm.layer.config.sticker_pricetag_basic.gap) + "px;\n        align-items: " + _vm.h_align + ";\n      "))},[_c('span',{style:({
      color: _vm.layer.config.sticker_pricetag_basic.top_text_color,
      backgroundColor: _vm.layer.config.sticker_pricetag_basic.top_bg_color,
      fontSize: (_vm.layer.fontSize * 0.55 * _vm.topTextRatio) + 'px',
      textDecoration: _vm.layer.config.sticker_pricetag_basic.line_through ? 'line-through' : 'none',
      fontFamily: _vm.font,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      padding: _vm.layer.config.sticker_pricetag_basic.bg_padding + 'px',
      borderRadius: _vm.layer.config.sticker_pricetag_basic.bg_radius + 'px',
      display: 'inline-block', /* Zorgt dat de span alleen zo breed is als de content */
    }),domProps:{"innerHTML":_vm._s(_vm.top_textWithBreaks)}}),_vm._v(" "),_c('span',{style:({
      color: _vm.layer.config.sticker_pricetag_basic.main_text_color,
      backgroundColor: _vm.layer.config.sticker_pricetag_basic.main_bg_color,
      fontSize: (_vm.layer.fontSize * 0.55) + 'px',
      fontFamily: _vm.font,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      padding: _vm.layer.config.sticker_pricetag_basic.bg_padding + 'px',
      borderRadius: _vm.layer.config.sticker_pricetag_basic.bg_radius + 'px',
      display: 'inline-block', /* Zorgt dat de span alleen zo breed is als de content */
    }),domProps:{"innerHTML":_vm._s(_vm.textWithBreaks)}})])])}
var staticRenderFns = []
export { render, staticRenderFns }