import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class StickerArrows extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Sticker arrows'
  }

  labels() {
    return ['sticker']
  }

  icon() {
    return 'fa-solid fa-arrow-up'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'buttonGroup',
          name: 'Arrow Shape',
          hide_label: false,
          key: 'shape',
          options: [
            {
              type: 'text',
              name: 'Bend Bold',
              value: 'bend_bold',
            },
            {
              type: 'text',
              name: 'Bend Semibold',
              value: 'bend_semibold',
            },
          ]
        },
        [
          {
            type: 'color',
            name: 'Color',
            key: 'line_color',
          },
          {
            type: 'buttonGroup',
            name: 'Mirror',
            hide_label: false,
            key: 'mirror',
            options: [
              {
                type: 'text',
                name: 'Normal',
                icon: 'fa-regular fa-arrow-left',
                value: 'normal',
              },
              {
                type: 'text',
                name: 'Mirror',
                icon: 'fa-regular fa-arrow-right',
                value: 'mirror',
              },
            ]
          },
        ]
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            sticker_arrows: {
              ...baseConfig.config.sticker_arrows,
              line_color: '#ffffff',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 400,
      height: 400,
      align: 'center',
      ...this.opts,
      config: {
        component: {
          type: 'sticker_arrows',
          name: 'Outline image',
        },
        sticker_arrows: {
          line_color: '#000000',
          shape: 'bend_bold',  // Standaard geselecteerde pijl
          mirror: 'normal',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
