<template>
    <transition name="scene-picker">
        <div v-if="show" class="scene-picker-overlay">
            <div class="scene-picker-view">
                <div class="scene-picker-header">
                    <h3 class="scene-picker-title">{{ scenes.length }} Video Clips</h3>
                    <button class="button button--dark button--icon" v-on:click="$emit('close')" @click.stop>
                        <i class="fa-regular fa-xmark"></i>
                    </button>
                </div>

                <div class="scene-picker-content">
                    <div class="scene-picker-grid">
                        <div v-for="(scene, index) in scenes" :key="`scene_${index}`" class="scene-picker-item">
                            <div class="scene-picker-video">
                                <Video :url="asset.file_url" :start="scene[0]" :end="scene[1]" />
                            </div>
                            <div class="scene-picker-info">
                                <span class="scene-picker-duration">
                                    Duration: {{ (parseFloat(scene[1]) - parseFloat(scene[0])).toFixed(2) }}s
                                </span>
                                <button v-if="hasSelectButton" class="button" style="background: white; color: black;"
                                    v-on:click="selectScene(scene)">
                                    Use this clip
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Video from '../video.vue'

export default {
    name: 'ScenePicker',

    components: {
        Video
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        asset: {
            type: Object,
            required: true
        },
        hasSelectButton: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        scenes() {
            if (this.asset.feed_element && this.asset.feed_element['scenes']) {
                return this.asset.feed_element['scenes']
            }
            return []
        }
    },

    methods: {
        selectScene(scene) {
            this.$emit('select', {
                ...this.asset.feed_element,
                image_link: `${this.asset.file_url}#t=${scene[0]},${scene[1]}`,
            });

            this.$emit('close');
        }
    }
}
</script>