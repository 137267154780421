<template>
  <div>
    <transition :css="fixed" name="medialibrary-slide">
      <div class="medialibrary__sidebar" id="teleportTo" :class="{ 'medialibrary__sidebar--not-fixed': !fixed }"
        v-on-clickaway="fixed ? away : () => { }" v-if="l_show">
        <Dropzone :busy="false" @click.self="active_asset = null" :on_change="(files) => create_assets(files)"
          v-slot="dropzone">

          <MediaLibHeader :tab.sync="tab" :show_stock_assets="show_stock_assets" :loading="loading"
            :custom-feed="custom_feed" :custom-feed-name="custom_feed_name">
            <template #custom_tab_selector>
              <slot name="custom_tab_selector"></slot>
            </template>
            <template #extra_actions>
              <slot name="extra_actions"></slot>
            </template>
          </MediaLibHeader>

          <!--submenu starts here-->
          <span v-if="tab == 'custom'">
            <slot name="custom_tab_content" v-bind:pick_asset="pick_asset"></slot>
          </span>

          <div class="media_lib__body" v-if="tab == custom_feed_name && custom_feed">
            <div class="media_lib__body__navbar">
              <div v-for="(asset, index) in custom_feed.data" :key="`custom_feed_asset_${index}`"
                class="medialibrary__asset-container">
                <div class="medialibrary__asset">
                  <span v-if="asset.data.asset_url ? !asset.data.asset_url.toLowerCase().includes('mp4') : false">
                    <img :src="asset.data.asset_url" :id="`asset_${asset.data.asset_sid}`">
                  </span>

                  <span v-else>
                    <Video :url="asset.data.image_link" :poster="asset.data.thumbnail_url"></Video>
                    <div class="medialibrary__vid-icon">
                      <i class="fa-regular fa-video"></i>
                    </div>
                  </span>
                  <span v-if="asset.data.title">
                    {{ asset.data.title.substring(0, 10) }}...
                  </span>
                  <div class="medialibrary__asset-buttons">
                    <button v-if="on_asset" v-on:click.self="e => pick_asset({
                      ...asset.data,
                      asset_url: asset.data.image_link
                    })">Use</button>
                  </div>

                  <div class="medialibrary__asset-action">
                    <button class="button--dark" v-on:click="delete_feed_row(asset)">
                      <i class="fa-regular fa-trash"></i>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="media_lib__body" v-if="tab == 'assets' && show_body">
            <div class="media_lib__body__navbar">
              <FilterBar :filters="filters" :labels="folder.labels" :show-filters="show_filters"
                @clear-filters="filters = []" @toggle-filter="toggle_filter" />

              <UploadSection :search-query.sync="searchQuery" @files-selected="create_assets" />
            </div>

            <!--media lib starts here-->
            <div class="medialibrary__category-container">

              <!-- Loading states -->
              <div v-if="loading && !initialLoadComplete" class="medialibrary__loading">
                <div class="loader"></div>
                <p>Loading your media...</p>
              </div>

              <!-- Active uploads -->
              <div v-for="(asset, index) in active_uploads" :key="`ap_${index}`" class="medialibrary__asset-container">
                <div class="medialibrary__asset">
                  <img :src="asset.url" v-if="asset.type == 'image'" loading="lazy">
                  <div class="medialibrary__loader-holder">
                    <div class="medialibrary__upload-progress">
                      Uploading... {{ asset.progress }}%
                    </div>
                  </div>
                </div>
              </div>

              <!-- Asset grid -->
              <div v-if="visibleAssets.length > 0">
                <AssetGrid :assets="visibleAssets.map(asset => ({
                  ...asset,
                  overlay: asset.processing ? {
                    type: 'processing',
                    message: asset.msg || 'Processing...'
                  } : null
                }))" :selectedAssets="selected" @select="select_asset" @focus-point="active_asset = $event"
                  @remove-background="remove_background" @select-scene="active_asset = $event" @delete="delete_asset" />
              </div>

              <!-- Infinite scroll trigger -->
              <div ref="loadMoreTrigger" v-if="hasMoreAssets && visibleAssets.length > 0"
                class="medialibrary__load-more">
                <div v-if="isLoadingMore" class="loader loader--small"></div>
              </div>

              <!-- Empty state -->
              <div v-else-if="!loading && visibleAssets.length === 0" class="medialibrary__empty-state">
                <slot name="virgin">
                  <div class="medialibrary__dropzone-message">
                    <i class="fa-regular fa-cloud-arrow-up"></i>
                    <h3>Drag & drop your files here</h3>
                    <p>or use the upload button above</p>
                  </div>
                </slot>
              </div>

            </div>
          </div>

          <div class="media_lib__body" v-if="tab == 'feed'">
            <ProductFeed :hasSelectButton="!!on_asset" :onSelect="pick_asset" />
          </div>

          <div class="media_lib__body" v-if="tab == 'pexels'">
            <PexelsTab @select="pickPexelsAsset" />
          </div>

          <slot name="footer"></slot>

        </Dropzone>

      </div>
    </transition>

    <!-- Move FocusPicker outside the sidebar -->
    <!-- <Teleport to="body"> -->
    <FocusPicker v-if="active_asset && active_asset.content_type == 'image'" :on_close="e => active_asset = null"
      :url="get_focus_url(active_asset)" :show="active_asset !== null" :on_pick="(e) => on_focus_pick(active_asset, e)"
      @click.stop />
    <!-- </Teleport> -->

    <ScenePicker v-if="active_asset && active_asset.content_type === 'video'" :show="!!active_asset"
      :asset="active_asset" :hasSelectButton="!!on_asset" @close="active_asset = null" @select="pick_asset" />
  </div>
</template>
<script>
import api from '../store/api'
import Panel from './panel.vue'
import Teleport from 'vue2-teleport'
import FocusPicker from '../../assets/focuspicker.vue'
import Dropzone from '../dropzone.vue'
import axios from 'axios';
import { MediaFolderChannel } from '../../lib/consumers/media_folder.js';
import { mixin as clickaway } from 'vue-clickaway2'
import { debounce } from "debounce";
import Video from './video.vue'
import FeedSelector from './feed_selector.vue'
import { EventBus } from '../../lib/media/eventbus.js'
import QRCode from 'qrcode'
import AssetGrid from './media/asset-grid.vue'
import assetsApi from '../services/assets-api'
import MediaLibHeader from './media/media-lib-header.vue'
import UploadSection from './media/upload-section.vue'
import FilterBar from './media/filter-bar.vue'
import PexelsTab from './media/pexels-tab.vue'
import ProductFeed from './media/product-feed.vue'
import ScenePicker from './media/scene-picker.vue'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

// Extract asset update logic to a separate service
const assetService = {
  updateAssetInList(assets, updatedAsset, updates) {
    return assets.map(asset => {
      if (asset.sid === updatedAsset.sid) {
        return { ...asset, ...updates };
      }
      return asset;
    });
  },

  createAssetUpdate(asset, isProcessing, msg = null) {
    return {
      ...asset,
      processing: isProcessing,
      msg: msg || (isProcessing ? 'Processing...' : null),
      labels: asset.labels,
      feed_element: asset.feed_element,
      file_url: isProcessing ? asset.file_url : asset.file_url
    };
  }
};

export default {
  mixins: [clickaway],
  components: {
    Video,
    FocusPicker,
    Dropzone,
    FeedSelector,
    Panel,
    Teleport,
    AssetGrid,
    MediaLibHeader,
    UploadSection,
    FilterBar,
    PexelsTab,
    ProductFeed,
    ScenePicker
  },
  props: {
    only_types: {
      type: Array,
      default: () => ['image', 'video']
    },
    fixed: {
      type: Boolean,
      default: true
    },
    custom_feed: {
      type: Object,
      required: false,
      default: null
    },
    custom_feed_name: {
      type: String,
      required: false,
      default: 'custom'
    },
    on_asset: {
      type: Function,
      required: false,
      default: () => { }
    },
    show: {
      type: Boolean,
      default: false
    },
    on_close: {
      type: Function,
      required: false,
      default: () => { }
    },
    on_open: {
      type: Function,
      required: false,
      default: () => { }
    },
    select_on_upload: {
      type: Boolean,
      default: false
    },
    show_filters: {
      type: Boolean,
      default: true
    },
    default_tab: {
      type: String,
      default: 'assets'
    },
    show_stock_assets: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visibleAssets() {
      if (this.filters.length === 0) {
        return this.assets
      }

      return this.assets.filter(asset => {
        return this.filters.some(filter => asset.labels.includes(filter))
      })
    }
  },
  data() {
    return {
      assets: [],
      loading: true,
      tab: this.$props.default_tab,
      folder: {
        labels: {}
      },
      searchQuery: "",
      videos: [],
      images: [],
      selected: [],
      filters: [],
      active_uploads: [],
      active_dup_processes: [],
      active_asset: null,
      active_product: null,
      l_show: false,
      assets_page: 1,
      show_body: true,
      field_id: null,
      feed_search_results: [],
      feed_page: 1,
      feed_total_pages: 1,
      feed_id: null,
      public_qr_code: null,
      upload_from_phone_active: false,
      page: 1,
      perPage: 25,
      isLoadingMore: false,
      hasMoreAssets: true,
      totalPages: 1,
      totalCount: 0,
      initialLoadComplete: false
    }
  },
  watch: {
    tab: {
      immediate: true,
      handler: function (val) {
        if (val === null) {
          this.show_body = false
        } else {
          this.show_body = true
          // Reset pagination state when changing tabs
          this.page = 1
          this.hasMoreAssets = true
          this.assets = []

          if (val === 'assets') {
            this.loadInitialAssets()
          } else if (val === 'feed' && this.feed_search_results.length === 0) {
            this.debounced_search("")
          }
        }
      }
    },
    filters: {
      handler: function () {
        // Reset pagination state when changing filters
        this.page = 1
        this.hasMoreAssets = true
        this.assets = []
        this.loadInitialAssets()
      }
    },
    searchQuery: {
      handler: function (val) {
        // Reset pagination state when searching
        this.page = 1
        this.hasMoreAssets = true
        this.assets = []
        this.debounced_search(val)
      }
    },
    show: {
      immediate: true,
      handler: async function (val) {
        this.l_show = val
        if (val === true) {
          // Reset state when opening
          this.loading = true
          this.page = 1
          this.hasMoreAssets = true
          this.assets = []

          // Load initial data
          const folder = await api.get_media_folder(this.only_types)
          this.folder = folder

          // Load assets if we're on the assets tab
          if (this.tab === 'assets') {
            await this.loadInitialAssets()
          }

          this.loading = false
        } else {
          // Clean up when closing
          if (this._observer) {
            this._observer.disconnect()
            this._observer = null
          }

          // Reset state
          this.assets = []
          this.page = 1
          this.hasMoreAssets = true
          this.isLoadingMore = false
        }
      }
    }
  },
  async mounted() {
    const folder = await api.get_media_folder(this.only_types)
    this.folder = folder
    this.public_qr_code = await QRCode.toDataURL(`${window.location.origin}/public/user_media_folders_upload/${this.folder.shared_secret}`)
    this.loading = false
    let _self = this
    EventBus.$emit('ready', {})
    EventBus.$on('show', (e) => {
      this.l_show = true
      this.on_open()
      if (e && e.field_id)
        this.field_id = e.field_id
      if (e && e.tab) {
        this.tab = e.tab
      }
      setTimeout(() => {
        if (this.$refs.search) {
          this.$refs.search.focus()
        }
      }, 15)
      EventBus.$emit('open', {})
    })

    EventBus.$on('hide', (e) => {
      this.l_show = false
      this.field_id = null
      this.on_close()
      EventBus.$emit('closed', {})
    })

    EventBus.$on('upload', async (e) => {
      EventBus.$emit('upload_in_progress', { completion: 0, msg: null })
      await this.create_assets([e.file])
      EventBus.$emit('upload_finished', {})
    })

    MediaFolderChannel.onReceive(this.handleAssetEvent);

    this.loadInitialAssets()
  },
  methods: {
    async remove_background(asset) {
      await api.remove_background(asset)
    },
    set_feed(e) {
      this.feed_id = e.target.value
      this.feed_page = 1
      this.debounced_search("")
    },
    async manual_upload_files(e) {
      await this.create_assets([...this.$refs.manual_upload.files])
    },
    manual_upload() {
      this.$refs.manual_upload.click()
    },
    away() {
      // Clean up before closing
      if (this._observer) {
        this._observer.disconnect()
        this._observer = null
      }
      this.l_show = false
      this.on_close()
    },
    load_more_products() {
      this.feed_page = this.feed_page + 1
      this.debounced_search(this.searchQuery, (results) => {
        this.feed_search_results = this.feed_search_results.concat(results)
      })
    },
    load_more_assets() {
      this.assets_page = this.assets_page + 1
      this.debounced_search(this.searchQuery, (results) => {
        this.folder.assets = this.folder.assets.concat(results)
      })
    },
    pick_asset(element) {
      if (typeof element.adflow_classification_map === 'object' && element.adflow_classification_map instanceof Array) {
        let map = element.adflow_classification_map.map(el => {
          if (el[0] === element.asset_url)
            return el
        }).filter(Boolean).flat()
        try {
          element = {
            ...element,
            adflow_image_classification: map.pop().pop()
          }
        } catch (e) {
          console.error(e)
        }
      }
      if (element.asset_url.toLowerCase().includes('mp4')) {
        element.adflow_image_classification = 'lifestyle'
      }
      if (this.on_asset && !this.field_id)
        this.on_asset(element)
      EventBus.$emit('on_asset_' + this.field_id, element)
      EventBus.$emit('asset_picked', element)
    },
    debounced_search: debounce(async function (query) {
      this.loading = true
      try {
        const result = await assetsApi.fetchAssets({
          page: 1,
          perPage: this.perPage,
          search: query,
          filters: this.filters
        })

        this.assets = result.assets
        this.totalPages = result.total_pages
        this.totalCount = result.total_count
        this.hasMoreAssets = this.page < this.totalPages
        this.page = 1
      } catch (error) {
        console.error('Error searching assets:', error)
      } finally {
        this.loading = false
      }
    }, 500),
    get_focus_url(asset) {
      let url = new URL(asset.file_url)
      let focus_x = url.searchParams.get('focus_x')
      let focus_y = url.searchParams.get('focus_y')
      if (asset.feed_element['focus']) {
        if (!focus_x)
          url.searchParams.append('focus_x', asset.feed_element['focus']['focus_x'])
        if (!focus_y)
          url.searchParams.append('focus_y', asset.feed_element['focus']['focus_y'])
      }
      return url.toString()
    },
    async on_focus_pick(asset, e) {
      let link = new URL(e)
      let focus_x = link.searchParams.get('focus_x')
      let focus_y = link.searchParams.get('focus_y')
      let focus = { focus_x, focus_y }
      if (this.folder.assets) {
        this.folder.assets = this.folder.assets.map(a => {
          if (a.id === asset.id) {
            a.feed_element['focus'] = focus
          }
          return a
        })
      }
      await this.update_asset(asset)
    },
    is_split(asset) {
      if (asset.feed_element['scenes']) {
        return asset.feed_element['scenes'].length > 1
      }
      return false
    },
    has_focus_points(asset) {
      return !!asset.feed_element['focus']
    },
    is_selected(asset) {
      return !!this.selected.find(a => a.id === asset.id)
    },
    select_asset(asset) {
      this.pick_asset({
        ...asset.feed_element,
        image_link: this.get_focus_url(asset),
      })
    },
    async create_assets(files, opts = { external: false }) {
      const fileObjects = this.prepareFiles(files);
      await this.uploadFiles(fileObjects, opts);
    },
    prepareFiles(files) {
      return files.map(file => {
        if (typeof file === 'string' && file.startsWith('data:image')) {
          return this.convertBase64ToFile(file);
        }
        return file;
      });
    },
    convertBase64ToFile(base64String) {
      const byteString = atob(base64String.split(',')[1]);
      const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      return new File([blob], 'image.png', { type: mimeString });
    },
    async uploadFiles(files, opts) {
      await Promise.all(files.map(file => this.uploadSingleFile(file, opts)));
    },
    async uploadSingleFile(file, opts) {
      const uploadPreview = this.createUploadPreview(file);
      this.active_uploads.push(uploadPreview);

      try {
        const asset = await this.performUpload(file, uploadPreview);
        this.handleSuccessfulUpload(asset, uploadPreview, opts);
      } catch (error) {
        console.error('Error uploading file:', error);
        this.removeUploadPreview(uploadPreview);
      }
    },
    createUploadPreview(file) {
      return {
        progress: 0,
        url: URL.createObjectURL(file),
        type: file.type.split('/')[0]
      };
    },
    async performUpload(file, preview) {
      return await api.upload_file(file, {
        onUploadProgress: progressEvent => this.updateUploadProgress(progressEvent, preview)
      });
    },
    updateUploadProgress(progressEvent, preview) {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);
      preview.progress = percentage;
      EventBus.$emit('upload_in_progress', { completion: percentage });
    },
    handleSuccessfulUpload(asset, preview, opts) {
      this.removeUploadPreview(preview);
      const newAsset = this.createNewAsset(asset, preview);
      this.assets.unshift(newAsset);
      EventBus.$emit('asset_uploaded', { completion: 100, asset, opts });
    },
    createNewAsset(asset, preview) {
      return {
        ...asset,
        processing: true,
        msg: 'Initializing...',
        file_url: preview.url,
        labels: [],
        feed_element: asset.feed_element || {}
      };
    },
    removeUploadPreview(preview) {
      this.active_uploads = this.active_uploads.filter(u => u !== preview);
    },
    handleAssetEvent(data) {
      const { event, asset: receivedAsset, msg } = data;
      this.folder.labels = data.labels;

      switch (event) {
        case 'asset_processing':
          this.handleAssetProcessing(receivedAsset, msg);
          break;
        case 'asset_processed':
          this.handleAssetProcessed(receivedAsset, msg);
          break;
        case 'background_removal_finished':
          this.handleBackgroundRemoval(receivedAsset);
          break;
      }
    },
    handleAssetProcessing(asset, msg) {
      this.assets = assetService.updateAssetInList(
        this.assets,
        asset,
        assetService.createAssetUpdate(asset, true, msg)
      );
      EventBus.$emit('asset_processing', { completion: 100, msg, asset });
    },
    handleAssetProcessed(asset, msg) {
      this.assets = assetService.updateAssetInList(
        this.assets,
        asset,
        assetService.createAssetUpdate(asset, false, msg)
      );
      EventBus.$emit('asset_processed', { completion: 100, msg, asset });

      if (this.select_on_upload) {
        this.pick_asset(asset.feed_element);
      }
    },
    handleBackgroundRemoval(asset) {
      this.active_dup_processes = this.active_dup_processes.filter(p =>
        p.id !== asset.feed_element['parent_asset_id']
      );
      this.assets.unshift(asset);
    },
    async delete_asset(asset) {
      this.assets = this.assets.filter(a => a.id !== asset.id)
      await axios.delete(`${window.location.origin}/user_media_folder/assets/${asset.sid}.json`)
    },
    async delete_feed_row(row) {
      this.$store.dispatch('delete_asset', row.data)
    },
    async update_asset(asset) {
      const result = await axios.patch(
        `${window.location.origin}/user_media_folder/assets/${asset.sid}.json`,
        { feed_element: asset.feed_element }
      )

      this.assets = this.assets.map(a => {
        if (a.id === asset.id) {
          return { ...a, feed_element: result.data.feed_element }
        }
        return a
      })
    },
    toggle_filter(label) {
      this.active_asset = null
      this.filters = []
      this.filters.push(label)
      // if(this.filters.includes(label)){
      //   this.filters = this.filters.filter(f => f !== label)
      // } else {
      //   this.filters.push(label)
      // }
    },
    async loadInitialAssets() {
      this.loading = true
      try {
        const result = await assetsApi.fetchAssets({
          page: 1,
          perPage: this.perPage,
          search: this.searchQuery,
          filters: this.filters
        })

        this.assets = result.assets
        this.totalPages = result.total_pages
        this.totalCount = result.total_count
        this.hasMoreAssets = this.page < this.totalPages
        this.initialLoadComplete = true

        // Re-initialize infinite scroll after loading new content
        await this.$nextTick()
        this.setupInfiniteScroll()
      } catch (error) {
        console.error('Error loading initial assets:', error)
      } finally {
        this.loading = false
      }
    },
    setupInfiniteScroll() {
      // Clean up any existing observer
      if (this._observer) {
        this._observer.disconnect()
        this._observer = null
      }

      // Only set up observer if we're visible and have more assets to load
      if (!this.l_show || !this.hasMoreAssets) return

      this._observer = new IntersectionObserver(
        (entries) => {
          const target = entries[0]
          if (target.isIntersecting && !this.isLoadingMore && this.hasMoreAssets) {
            this.loadMoreAssets()
          }
        },
        {
          root: null,
          rootMargin: '100px',
          threshold: 0.1
        }
      )

      this.$nextTick(() => {
        const trigger = this.$refs.loadMoreTrigger
        if (trigger) {
          this._observer.observe(trigger)

          // Check if trigger is already visible without scrolling
          const rect = trigger.getBoundingClientRect()
          const isVisible = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          )

          // If trigger is visible and we have more assets, load them
          if (isVisible && this.hasMoreAssets && !this.isLoadingMore && this.l_show) {
            this.loadMoreAssets()
          }
        }
      })
    },
    async loadMoreAssets() {
      if (this.isLoadingMore || !this.hasMoreAssets) return

      this.isLoadingMore = true
      try {
        const nextPage = this.page + 1
        const result = await assetsApi.fetchAssets({
          page: nextPage,
          perPage: this.perPage,
          search: this.searchQuery,
          filters: this.filters
        })

        if (result.assets && result.assets.length > 0) {
          this.assets = [...this.assets, ...result.assets]
          this.page = nextPage
          this.totalPages = result.total_pages
          this.totalCount = result.total_count
          this.hasMoreAssets = this.page < this.totalPages
        } else {
          this.hasMoreAssets = false
        }
      } catch (error) {
        console.error('Error loading more assets:', error)
      } finally {
        this.isLoadingMore = false
      }
    },
    async performSearch(query) {
      this.loading = true
      this.page = 1
      this.hasMoreAssets = true

      try {
        const result = await api.get_media_folder(this.only_types, {
          page: 1,
          per_page: this.perPage,
          search: query
        })
        this.folder.assets = result.assets || []
        this.totalPages = result.total_pages
        this.totalCount = result.total_count
        this.hasMoreAssets = this.page < this.totalPages
      } catch (error) {
        console.error('Error searching assets:', error)
      } finally {
        this.loading = false
      }
    },
    pickPexelsAsset(asset) {
      this.pick_asset({
        ...asset,
        image_link: asset.asset_url
      })
    }
  },
  beforeDestroy() {
    if (this._observer) {
      this._observer.disconnect()
    }
  }
}
</script>
