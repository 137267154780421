<template>
  <div :style="container_styles" style="position: relative; overflow: hidden;"
    :id="`image_layer_${animation_uid}_${layer.id}`">
    <OptimizedImage v-if="!focus" :src="clean_source" :styles="styles" @error="register_error"
      :key="`${clean_source}-${styleKey}`" />
  </div>
</template>

<script>
import { mustache } from '../../lib/parsers'

// Create a separate component for the image to prevent unnecessary re-renders
const OptimizedImage = {
  name: 'OptimizedImage',
  props: {
    src: {
      type: String,
      required: true
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      currentSrc: this.src,
      isLoading: true,
      loadedOnce: false
    }
  },
  computed: {
    baseStyle() {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        ...this.styles
      }
    }
  },
  render(h) {
    return h('div', {
      style: { position: 'relative', width: '100%', height: '100%' }
    }, [
      // Hidden preloader image
      h('img', {
        style: {
          ...this.baseStyle,
          opacity: 0,
          pointerEvents: 'none'
        },
        attrs: { src: this.src },
        on: {
          load: this.handlePreload,
          error: this.handleError
        }
      }),
      // Visible image
      h('img', {
        style: {
          ...this.baseStyle,
          opacity: this.isLoading && !this.loadedOnce ? 0 : 1,
          transition: 'opacity 0.2s ease-in-out'
        },
        attrs: { src: this.currentSrc },
        on: this.$listeners
      })
    ])
  },
  methods: {
    handlePreload(event) {
      this.currentSrc = this.src
      this.$nextTick(() => {
        this.isLoading = false
        this.loadedOnce = true
      })
    },
    handleError(event) {
      this.isLoading = false
      if (this.$listeners.error) {
        this.$listeners.error(event)
      }
    }
  },
  watch: {
    src(newSrc) {
      if (newSrc !== this.currentSrc) {
        this.isLoading = true
      }
    }
  }
}

export default {
  components: {
    OptimizedImage
  },
  props: {
    layer: {
      type: Object,
      required: true
    },
    samples: {
      type: Object,
      required: false,
      default: () => {
        return { data: [], indice: 0 }
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    on_error: {
      type: Function,
      required: false,
      default: () => { }
    },
    brandkit: {
      type: Object,
      required: false,
      default: () => { }
    },
    animation_uid: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      source: '/sample_image.png'
    }
  },
  computed: {
    clean_source() {
      try {
        const url = new URL(this.source);
        const focus_x = url.searchParams.get('focus_x');
        const focus_y = url.searchParams.get('focus_y');
        if (focus_x && focus_y && url.searchParams.toString() === `focus_x=${focus_x}&focus_y=${focus_y}`) {
          return url.origin + url.pathname;
        }
        return this.source
      } catch (e) {
        return this.source
      }
    },
    styles() {
      return {
        'object-fit': this.layer.config['object_fit'] || 'contain',
        'object-position': `${this.layer.config.object_position || 'center'}`,
        width: '100%',
        height: '100%'
      }
    },
    focus() {
      return this.layer.config['object_fit'] !== 'contain' && this.has_focus_points
    },
    background_position() {
      let y = Math.abs(this.focus_points.y) / 2 * 100
      let x = Math.abs(this.focus_points.x) / 2 * 100
      if (this.focus_points.y < 0) {
        y += 50
      } else {
        y = 50 - Math.abs(this.focus_points.y) * 50
      }
      if (this.focus_points.x > 0) {
        x += 50
      } else {
        x = 50 - Math.abs(this.focus_points.x) * 50
      }
      return `${x}% ${y}%`
    },
    container_styles() {
      if (this.focus) {
        return {
          width: `${this.layer.width}px`,
          height: `${this.layer.height}px`,
          'background-image': `url(${this.clean_source})`,
          'background-size': 'cover',
          'background-position': this.background_position,
          '-webkit-backface-visibility': 'hidden',
          '-moz-backface-visibility': 'hidden',
          '-ms-backface-visibility': 'hidden',
          '-webkit-transform': 'translate3d(0, 0, 0)'
        }
      } else {
        return { width: `${this.layer.width}px`, height: `${this.layer.height}px` }
      }
    },
    sample: function () {
      let sample = this.samples.data[this.samples.indice]
      let brand = { ...this.brand }
      if (sample.brandkit && sample.brandkit.logos && sample.brandkit.logos.length > 0 && sample.brandkit.logos.every(l => !!l)) {
        brand.logos = sample.brandkit.logos
      }
      return { ...sample, brandkit: brand } || {}
    },
    brand: function () {
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if (this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit) {
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }

      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
    focus_points() {
      try {
        let link = new URL(this.source)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return { x: focus_x, y: focus_y }
      } catch (e) {
        return { x: 0, y: 0 }
      }
    },
    has_focus_points() {
      try {
        let link = new URL(this.source)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return !!(focus_x && focus_y)
      } catch (e) {
        return false
      }
    },
    styleKey() {
      return JSON.stringify(this.styles)
    },
  },
  watch: {
    layer: {
      deep: true,
      handler(newval, oldval) {
        let image_url = newval.config.image_url
        try {
          image_url = mustache.fill_in(newval.config.image_url, this.sample)
        } catch (e) {

        }
        if (this.is_valid_url(image_url)) {
          this.source = image_url
          // this.set_focus()
        }
      }
    },
    samples: {
      immediate: true,
      deep: true,
      handler(newSamples) {
        try {
          if (newSamples.data.length > 0) {
            const image_url = mustache.fill_in(this.layer.config.image_url, this.sample)
            if (this.is_valid_url(image_url)) {
              this.source = image_url
            } else {
              this.source = '/sample_image.png'
            }
          } else if (this.is_valid_url(this.layer.config.image_url)) {
            this.source = this.layer.config.image_url
          } else {
            this.source = '/sample_image.png'
          }
        } catch (e) {
          this.source = '/sample_image.png'
        }
      }
    }
  },
  methods: {
    register_error(e) {
      console.error(e)
      this.source = '/sample_image.png'
      this.on_error({ 'type': 'load', 'value': e.target.src })
    },
    is_valid_url(string) {
      // The pattern checks for the presence of "http://" or "https://",
      // followed by any characters, and ending in a valid image extension
      // possibly followed by query parameters. The image extension part is optional.

      //remove query parameters
      string = string.split('?')[0];
      const urlPattern = /^https?:\/\/.*(\.(jpeg|jpg|gif|png|webp)(\?.*)?)?$/i;
      //remove whitespace
      string = string.replace(/\s/g, '');
      return urlPattern.test(string);
    }
  }
}
</script>