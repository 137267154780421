<template>
  <div :class="cls" :style="{ 'background-image': checker ? `url('/checkerboard.png')` : null }"
    style="background-size: cover;">
    <div v-if="!loaded" style="text-align: center; padding-top: 12px">
      <div style="margin: 0 auto" class="loader"></div> <br>
    </div>
    <img style="width: 100%;" :style="{
      'display': loaded ? 'block' : 'none',
      'height': loaded ? '100%' : '0px',
      'object-fit': 'contain'
    }" :src="placeholder" @load="loaded = true"
      @error="placeholder = '/sample_image.png'" />
  </div>
</template>

<script>
import axios from '../lib/nimrod/axios'
export default {
  props: {
    sid: String,
    classes: Object,
    url: String,
    checker: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loaded: false,
      placeholder: '/sample_image.png',
      cls: {},
    }
  },
  mounted() {
    this.cls = this.$props.classes
    if (this.$props.sid) {
      this.fetch_thumb_url().then((url) => {
        if (url) {
          this.placeholder = url
          this.$forceUpdate()
        }
      })
    } else if (!this.$props.sid && this.$props.url) {
      this.placeholder = this.$props.url
    }
  },
  methods: {
    async fetch_thumb_url() {
      const data = await axios.get(`/assets/${this.sid}/thumb.json`)
      return data.data['url']
    }
  }
}
</script>
