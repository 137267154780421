<template>
    <div class="media_lib__body">
        <div class="media_lib__body__navbar">
            <FeedSelector :on_feed_select="onFeedSelect" style="width: 200px" />
            <input type="text" placeholder="Search..." v-model="searchQuery" ref="search">
        </div>

        <div class="medialibrary__category-container">
            <!-- Loading states -->
            <div v-if="loading && !products.length" class="medialibrary__loading">
                <div class="loader"></div>
                <p>Loading products...</p>
            </div>

            <!-- Product Grid -->
            <div v-if="products.length" class="medialibrary__grid">
                <div v-for="product in products" :key="`product_${product.id}`" class="medialibrary__asset-container"
                    v-on:click="e => onSelectProduct({
                        ...product,
                        asset_url: product.image_link
                    })">
                    <div class="medialibrary__asset">
                        <img :src="product.image_link" :id="`product_${product.id}`" loading="lazy">
                        <div v-if="hasAdditionalImages(product)" class="medialibrary__asset-focus-label">
                            {{ additionalImagesForProduct(product).length }} image{{
                                additionalImagesForProduct(product).length > 1 ? 's' : '' }}
                        </div>
                        <div v-if="product.adflow_image_classification == 'lifestyle'"
                            class="medialibrary__asset-focus-label" style="top: 25px; background: #FFA383;">
                            Lifestyle
                        </div>
                        {{ product.title.substring(0, 10) }}...
                        <div class="medialibrary__asset-buttons"></div>
                        <div class="medialibrary__asset-action">
                            <button class="button--dark" v-on:click.stop="activeProduct = product">
                                <i class="fa-regular fa-images"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Infinite scroll trigger -->
            <div ref="loadMoreTrigger" v-if="hasMore && products.length > 0" class="medialibrary__load-more">
                <div v-if="loadingMore" class="loader loader--small"></div>
            </div>

            <!-- Empty state -->
            <div v-if="!loading && !products.length" class="medialibrary__empty-state">
                <div class="medialibrary__dropzone-message">
                    <i class="fa-regular fa-box"></i>
                    <h3>No products found</h3>
                    <p>Try adjusting your search</p>
                </div>
            </div>
        </div>

        <!-- Product Detail View -->
        <transition name="product-detail">
            <div v-if="activeProduct" class="product-detail-overlay">
                <div class="product-detail-view">
                    <div class="product-detail-header">
                        <h3 class="product-detail-title">{{ activeProduct.title }}</h3>
                        <button class="button button--dark button--icon" v-on:click="activeProduct = null">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                    </div>

                    <!-- Main Product Image -->
                    <div class="product-detail-content">
                        <div class="medialibrary__asset-container">
                            <div class="medialibrary__asset" v-on:click="e => onSelectProduct({
                                ...activeProduct,
                                asset_url: activeProduct.image_link
                            })">
                                <img :src="activeProduct.image_link" :id="`product_${activeProduct.id}`">
                            </div>
                        </div>

                        <!-- Additional Images -->
                        <div v-if="additionalImages.length" class="product-detail-additional">
                            <h4 class="product-detail-subtitle">Additional Images</h4>
                            <div class="product-detail-grid">
                                <div v-for="(image_link, index) in additionalImages" :key="`additional_image_${index}`"
                                    class="medialibrary__asset-container">
                                    <div class="medialibrary__asset" v-on:click="e => onSelectProduct({
                                        ...activeProduct,
                                        asset_url: image_link,
                                        image_link: image_link
                                    })">
                                        <img :src="image_link" :id="`add_image_${index}`">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import FeedSelector from '../feed_selector.vue'
import { debounce } from 'debounce'
import api from '../../store/api'

export default {
    name: 'ProductFeed',

    components: {
        FeedSelector
    },

    props: {
        hasSelectButton: {
            type: Boolean,
            default: true
        },
        onSelect: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            products: [],
            activeProduct: null,
            searchQuery: '',
            page: 1,
            totalPages: 1,
            feedId: null,
            loading: false,
            loadingMore: false,
            hasMore: true,
            isVisible: true
        }
    },

    computed: {
        additionalImages() {
            if (this.activeProduct && typeof this.activeProduct.additional_image_link === 'string') {
                return [this.activeProduct.additional_image_link]
            } else if (this.activeProduct && Array.isArray(this.activeProduct.additional_image_link)) {
                return this.activeProduct.additional_image_link
            }
            return []
        }
    },

    methods: {
        additionalImagesForProduct(product) {
            if (product.additional_image_link && typeof product.additional_image_link === 'string') {
                return [product.additional_image_link]
            } else if (product.additional_image_link && Array.isArray(product.additional_image_link)) {
                return product.additional_image_link
            }
            return []
        },

        hasAdditionalImages(product) {
            return product.additional_image_link && product.additional_image_link.length > 0
        },

        onFeedSelect(e) {
            this.feedId = e.target.value
            this.page = 1
            this.searchProducts()
        },

        onSelectProduct(product) {
            this.onSelect(product)
        },

        async searchProducts(append = false) {
            if (this.loading || !this.feedId) return

            const isInitialLoad = !append
            if (isInitialLoad) {
                this.loading = true
            } else {
                this.loadingMore = true
            }

            try {
                let params = {
                    feed_id: this.feedId,
                    page: this.page
                }

                const conditions = [
                    {
                        op: 'and',
                        children: this.searchQuery.split(' ').map(val => ({
                            op: 'contains',
                            column: 'title',
                            value: val
                        }))
                    },
                    {
                        op: 'or',
                        children: [
                            {
                                op: 'contains',
                                column: 'id',
                                value: this.searchQuery
                            },
                            {
                                op: 'contains',
                                column: 'adflow_image_classification',
                                value: this.searchQuery
                            },
                            {
                                op: 'contains',
                                column: 'link',
                                value: this.searchQuery
                            }
                        ]
                    }
                ]

                params = {
                    ...params,
                    conditions,
                    gate: 'or'
                }


                const response = await api.filter_feed(params)

                if (append) {
                    this.products = [...this.products, ...response.data.sample]
                } else {
                    this.products = response.data.sample
                }

                this.totalPages = response.data.total_pages || 1
                this.hasMore = this.page < this.totalPages

                // Re-observe after content changes
                this.$nextTick(() => {
                    this.setupInfiniteScroll()
                })
            } catch (error) {
                console.error('Error searching products:', error)
                this.products = []
                this.totalPages = 1
                this.hasMore = false
            } finally {
                this.loading = false
                this.loadingMore = false
            }
        },

        setupInfiniteScroll() {
            // Clean up any existing observer
            if (this._observer) {
                this._observer.disconnect()
                this._observer = null
            }

            // Only set up observer if we have more products to load and component is visible
            if (!this.isVisible || !this.hasMore) return

            this._observer = new IntersectionObserver(
                (entries) => {
                    const target = entries[0]
                    if (target.isIntersecting && !this.loadingMore && this.hasMore) {
                        this.loadMore()
                    }
                },
                {
                    root: null,
                    rootMargin: '100px',
                    threshold: 0.1
                }
            )

            this.$nextTick(() => {
                const trigger = this.$refs.loadMoreTrigger
                if (trigger) {
                    this._observer.observe(trigger)

                    // Check if trigger is already visible without scrolling
                    const rect = trigger.getBoundingClientRect()
                    const isVisible = (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    )

                    // If trigger is visible and we have more products, load them
                    if (isVisible && this.hasMore && !this.loadingMore && this.isVisible) {
                        this.loadMore()
                    }
                }
            })
        },

        async loadMore() {
            if (this.loadingMore || !this.hasMore) return
            this.page += 1
            await this.searchProducts(true)
        }
    },

    mounted() {
        this.isVisible = true
        if (this.feedId) {
            this.searchProducts()
        }
        this.setupInfiniteScroll()
    },

    beforeDestroy() {
        this.isVisible = false
        if (this._observer) {
            this._observer.disconnect()
            this._observer = null
        }
    },

    watch: {
        searchQuery: debounce(function () {
            this.page = 1
            this.products = []
            this.hasMore = true
            this.searchProducts()
        }, 500)
    }
}
</script>