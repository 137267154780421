<template>
    <div class="media_lib__navbar">
        <div class="button__tab_group">
            <div class="button__tab" :class="{ 'button__tab--active': tab == 'assets' }"
                v-on:click="$emit('update:tab', tab === 'assets' ? null : 'assets')">
                <i class="fa-regular fa-download"></i>
                My media
            </div>
            <div class="button__tab" :class="{ 'button__tab--active': tab == 'feed' }"
                v-on:click="$emit('update:tab', 'feed')">
                <i class="fa-regular fa-download"></i>
                Feed
            </div>
            <div class="button__tab" v-on:click="$emit('update:tab', 'custom')"
                :class="{ 'button__tab--active': tab == 'custom' }" v-if="$slots.custom_tab_selector">
                <slot name="custom_tab_selector"></slot>
            </div>
            <div class="button__tab" v-if="customFeed" :class="{ 'button__tab--active': tab == customFeedName }"
                v-on:click="$emit('update:tab', customFeedName)">
                <i class="fa-regular fa-upload"></i>
                Briefing
            </div>
            <div class="button__tab" :class="{ 'button__tab--active': tab == 'pexels' }" v-if="show_stock_assets"
                v-on:click="$emit('update:tab', 'pexels')">
                <i class="fa-regular fa-image"></i>
                Pexels
            </div>
            <div class="loader" v-if="loading"></div>
        </div>
        <div style="display:flex;">
            <slot name="extra_actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MediaLibHeader',

    props: {
        tab: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        customFeed: {
            type: Object,
            default: null
        },
        customFeedName: {
            type: String,
            default: 'custom'
        },
        show_stock_assets: {
            type: Boolean,
            default: false
        }
    }
}
</script>
