import Background from './types/background'
import Layer from '../../store/modules/layers/model'

export default class BgImageFade extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Image face'
  }

  labels() {
    return ['background']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  sizeConfig(size) {
    if (size) {
      const width = size.display_dimensions[0]
      const height = size.display_dimensions[1]
      switch (size.aspect_ratio) {
        case '1:1':
          return {
            width,
            height,
            config: {
              bg_image_fade: {
                direction: 'top',
              }
            }
          }
        case '16:9':
          return {
            width,
            height,
            config: {
              bg_image_fade: {
                direction: 'left',
              }
            }
          }
        case '9:16':
          return {
            width,
            height,
            config: {
              bg_image_fade: {
                direction: 'top',
              }
            }
          }
        default:
          return {}
      }
    }
    return {}
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'assetPicker',
          name: 'Image',
          key: 'image',
        },
        {
          type: 'slider',
          name: 'Fade size',
          key: 'gradient_size',
          min: 0,
          max: 100,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'Fade side',
          hide_label: false,
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
        {
          type: 'buttonGroup',
          name: 'Fade sides',
          hide_label: false,
          key: 'fade_sides',
          options: [
            {
              type: 'text',
              name: 'Single',
              icon: 'fa-regular fa-arrow-left',
              value: 'single',
            },
            {
              type: 'text',
              name: 'Both',
              icon: 'fa-regular fa-arrow-right',
              value: 'both',
            },
          ]
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_image_fade: {
              ...baseConfig.config.bg_image_fade,
              direction: 'top',
              fade_sides: 'single',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 1080,
      height: 1080,
      ...this.opts,
      unlinked_properties: ['component.direction'],
      config: {
        component: {
          type: 'bg_image_fade',
          name: 'Image fade',
        },
        bg_image_fade: {
          direction: 'top',
          gradient_size: 20,
          image: 'https://images.pexels.com/photos/30446269/pexels-photo-30446269.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940&focus_y=0&focus_x=0',
          fade_sides: 'both',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
