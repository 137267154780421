<template>
    <div class="briefing_wizard__form">
        <div class="form-group">
            <label>Campaign name<span class="required">*</span></label>
            <input type="text" v-model="formData.name" placeholder="Enter your campaign name"
                :class="{ 'error': showErrors && !isValid.name }" />
            <span class="error-message" v-if="showErrors && !isValid.name">Please enter a campaign name</span>
        </div>

        <div class="form-group">
            <label>Language</label>
            <v-select v-model="formData.language" :options="supported_languages" :searchable="true" :clearable="false"
                class="language-select">
                <template #option="{ flag, label }">
                    <span class="language-flag">{{ flag }}</span>
                    {{ label }}
                </template>
                <template #selected-option="{ flag, label }">
                    <span class="language-flag">{{ flag }}</span>
                    {{ label }}
                </template>
            </v-select>
        </div>

        <div class="form-group">
            <label>Target Audience <span class="char-count">{{ getLength(formData.audience) }}/500</span></label>
            <textarea v-model="formData.audience" maxlength="500"
                :class="{ 'near-limit': isNearLimit(formData.audience) }"
                placeholder="Describe your target audience (e.g. Young women in their 20s who suffer from stomach aches)"></textarea>
        </div>

        <div class="form-group">
            <label>Key Benefit <span class="char-count">{{ getLength(formData.benefit) }}/500</span></label>
            <input type="text" v-model="formData.benefit" maxlength="500"
                :class="{ 'near-limit': isNearLimit(formData.benefit) }"
                placeholder="Main benefit of your product (e.g. Reduce Stomach Pain)" />
        </div>

        <div class="form-group">
            <label>Context <span class="char-count">{{ getLength(formData.context) }}/500</span></label>
            <textarea v-model="formData.context" maxlength="500"
                :class="{ 'near-limit': isNearLimit(formData.context) }"
                placeholder="Provide context about your product and any current promotions"></textarea>
        </div>

        <div class="form-group">
            <label>Funnel Stage</label>
            <div class="briefing_wizard__funnel_container">
                <div v-for="stage in funnel_stages" :key="stage.value" class="briefing_wizard__funnel_option"
                    :class="{ 'briefing_wizard__funnel_option--active': formData.funnel_stage === stage.value }"
                    @click="updateFunnelStage(stage.value)">
                    <h4>{{ stage.label }}</h4>
                    <p>{{ stage.description }}</p>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Choose templates for your campaign</label>
            <div class="briefing_wizard__flow_options">

                <div class="briefing_wizard__flow_option"
                    :class="{ 'briefing_wizard__flow_option--active': template_source === 'adflow' }"
                    @click="updateTemplateSource('adflow')">
                    <div class="briefing_wizard__flow_option__icon">
                        <i class="fa-regular fa-wand-magic-sparkles"></i>
                    </div>
                    <div class="briefing_wizard__flow_option__content">
                        <h4>Use Adflow templates</h4>
                        <p>Choose from our collection of high-performing templates</p>
                        <ul>
                            <li><i class="fa-regular fa-check"></i> Proven ad layouts</li>
                            <li><i class="fa-regular fa-check"></i> Optimized for engagement</li>
                            <li><i class="fa-regular fa-check"></i> Industry-specific designs</li>
                        </ul>
                    </div>
                </div>
                <div class="briefing_wizard__flow_option"
                    :class="{ 'briefing_wizard__flow_option--active': template_source === 'own' }"
                    @click="updateTemplateSource('own')">
                    <div class="briefing_wizard__flow_option__icon">
                        <i class="fa-regular fa-folder-open"></i>
                    </div>
                    <div class="briefing_wizard__flow_option__content">
                        <h4>Use your templates</h4>
                        <p>Select from templates you've created and saved</p>
                        <ul>
                            <li><i class="fa-regular fa-check"></i> Use your branded templates</li>
                            <li><i class="fa-regular fa-check"></i> Maintain brand consistency</li>
                            <li><i class="fa-regular fa-check"></i> Quick access to your designs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group" v-show="template_source === 'own'">
            <label>Choose templates for your campaign</label>
            <TemplateSelector :brandkit="brandkit" :selected="formData.templates"
                @update:templates="updateSelectedTemplates" />
        </div>

        <div class="form-validation-message" v-if="showErrors && !isFormValid">
            Please fill in all required fields before continuing
        </div>
    </div>
</template>

<script>
import { FUNNEL_STAGES, SUPPORTED_LANGUAGES } from '../constants'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import TemplateSelector from './TemplateSelector.vue'
export default {
    name: 'CampaignForm',
    components: {
        vSelect,
        TemplateSelector
    },
    props: {
        initialData: {
            type: Object,
            required: true
        },
        brandkit: {
            type: Object,
            required: true
        }
    },
    data() {
        // First set up supported_languages and funnel_stages
        const supported_languages = SUPPORTED_LANGUAGES;
        const funnel_stages = FUNNEL_STAGES;

        // Then find the language option
        const currentLanguage = this.initialData.language;
        const languageOption = supported_languages.find(lang => lang.value === currentLanguage) || supported_languages[0];

        // Finally return the data object
        return {
            formData: {
                ...this.initialData,
                language: languageOption,
                templates: this.initialData.templates || []
            },
            funnel_stages,
            supported_languages,
            showErrors: false,
            template_source: this.initialData.templates.length > 0 ? 'own' : 'adflow'
        }
    },
    computed: {
        isValid() {
            return {
                name: !!this.formData.name?.trim(),
                language: !!this.formData.language,
                audience: !!this.formData.audience?.trim(),
                benefit: !!this.formData.benefit?.trim(),
                context: !!this.formData.context?.trim(),
                funnel_stage: !!this.formData.funnel_stage
            }
        },
        isFormValid() {
            return Object.values(this.isValid).every(valid => valid)
        }
    },
    methods: {
        isNearLimit(value) {
            return this.getLength(value) >= 450;
        },
        getLength(value) {
            return value ? value.length : 0;
        },
        updateFunnelStage(value) {
            this.formData = {
                ...this.formData,
                funnel_stage: value
            };
            this.$emit('update:form', this.formData);
        },
        findLanguageOption(value) {
            if (!value || !this.supported_languages) return this.supported_languages[0];
            return this.supported_languages.find(lang => lang.value === value) || this.supported_languages[0];
        },
        validate() {
            this.showErrors = true
            return this.isFormValid
        },
        updateTemplateSource(source) {
            this.template_source = source;
            this.templates = [];
            this.$emit('update:form', this.formData);
        },
        updateSelectedTemplates(templates) {
            this.formData.templates = templates;
            this.$emit('update:form', this.formData);
        }
    },
    watch: {
        'formData.language'(newVal) {
            if (!newVal) return;
            this.$emit('update:form', {
                ...this.formData,
                language: newVal.value
            });
        },
        template_source(newVal) {
            if (newVal === 'adflow') {
                this.formData.templates = [];
            }
        },
        formData: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.$emit('update:form', newVal)
                this.$emit('validation', this.isFormValid)
            }
        }
    }
}
</script>