import anime from 'animejs/lib/anime'
import Animation from '../../animation'

export default class ZoomSnap extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'ZoomSnap'
  }

  cleanup() {
    $(`.layer_${this.uid}_${this.layer.id}`).css('transform', 'scale(1)');
    $(`.layer_${this.uid}_${this.layer.id}`).css('transform-origin', 'center');
  }

  prepare() {
    $(`.layer_${this.uid}_${this.layer.id}`).css('transform', 'scale(1.1)');
    if (this.has_focus_points(this.layer.config.image_url)) {
      $(`.layer_${this.uid}_${this.layer.id}`).css(
        'transform-origin',
        this.calc_bg_pos(this.focus_points(this.layer.config.image_url))
      );
    } else {
      $(`.layer_${this.uid}_${this.layer.id}`).css('transform-origin', 'center');
    }
  }

  static pretty_name() {
    return 'ZoomSnap'
  }

  static int_name() {
    return 'composeable_zoom_snap'
  }

  static exposed_to() {
    return ['component', 'image', 'video', 'background']
  }

  static properties() {
    return {
      ...super.properties(),
    }
  }

  animation_config(duration = this.duration) {
    const configs = [
      {
        animation: {
          duration,
          targets: `#image_layer_${this.uid}_${this.layer.id}`,
          backgroundPosition: ['47% 47%', '53%, 53%'],
          easing: 'easeInOutSine',
          direction: 'alternate'
        },
        delay: 0
      },
      {
        animation:
        {
          targets: `.layer_${this.uid}_${this.layer.id}`,
          scale: [1.1, 1.8],
          duration: duration * 0.1,
          direction: this.loop() ? 'alternate' : 'normal',
          easing: 'easeInOutSine'
        },
        delay: duration * 0.6
      },
      {
        animation: {
          targets: `.layer_${this.uid}_${this.layer.id}`,
          opacity: [this.opacity(), this.opacity()],
          duration,
        },
        delay: 0
      }
    ]
    return configs
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    })

    this.animation_config().forEach((config) => {
      timeline.add(config.animation, config.delay)
    })

    return timeline
  }

  has_focus_points(source) {
    try {
      const link = new URL(source)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return !!(focus_x && focus_y)
    } catch (e) {
      return false
    }
  }

  focus_points(source) {
    try {
      const link = new URL(source)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return { focusx: focus_x, focusy: focus_y }
    } catch (e) {
      return { focusx: 0, focusy: 0 }
    }
  }

  calc_bg_pos({ focusx, focusy }) {
    let y = (Math.abs(focusy) / 2) * 100
    let x = (Math.abs(focusx) / 2) * 100
    if (focusy < 0) {
      y += 50
    } else {
      y = 50 - (Math.abs(focusy) * 50)
    }
    if (focusx > 0) {
      x += 50
    } else {
      x = 50 - (Math.abs(focusx) * 50)
    }
    return `${x}% ${y}%`
  }
}
