<template>
  <div
    :style="{
      width: layer.width + 'px',
      height: layer.height + 'px',
      position: 'relative',
      overflow: 'hidden'
    }"
  >
    <div :style="imageStyle"></div>
  </div>
</template>

<script>
export default {
  props: ['layer'],

  computed: {
    /**
     * URL van de afbeelding
     */
    image() {
      return this.layer?.config?.bg_image_fade?.image || '';
    },

    /**
     * Focus-punten uitlezen uit de URL: ?focus_x=.. & ?focus_y=..
     */
    focus_points() {
      try {
        const url = new URL(this.image);
        const x = parseFloat(url.searchParams.get('focus_x')) || 0;
        const y = parseFloat(url.searchParams.get('focus_y')) || 0;
        return { x, y };
      } catch (e) {
        return { x: 0, y: 0 };
      }
    },

    /**
     * Bepaal of er focus-punten zijn ingesteld
     */
    focus() {
      return this.focus_points.x !== 0 || this.focus_points.y !== 0;
    },

    /**
     * Zet de focus-punten om in percentages voor background-position
     */
    background_position() {
      const fx = this.focus_points.x;
      const fy = this.focus_points.y;
      // Eenvoudige berekening: 50% + (focus * 50%)
      const xPercent = 50 + (fx * 50);
      const yPercent = 50 - (fy * 50);
      return `${xPercent}% ${yPercent}%`;
    },

    /**
     * Style voor de achtergrondafbeelding + fade naar transparantie
     */
    imageStyle() {
      const config = this.layer?.config?.bg_image_fade || {};
      const gradientSize = config.gradient_size ?? 30;     // fade-percentage
      const position = config.direction || 'bottom';       // top/bottom/left/right
      const sides = config.fade_sides || 'single';         // 'single' of 'both'

      // Bereken de CSS voor mask-image:
      const maskGradient = this.getMaskGradient(position, gradientSize, sides);

      return {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${this.image})`,
        backgroundSize: 'cover',
        backgroundPosition: this.focus ? this.background_position : 'center',
        WebkitMaskImage: maskGradient,
        maskImage: maskGradient,
        WebkitMaskSize: '100% 100%',
        WebkitMaskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        maskRepeat: 'no-repeat'
      };
    }
  },

  methods: {
    /**
     * Geeft een linear-gradient string terug voor de mask-image,
     * op basis van de positie (top/bottom/left/right),
     * de fade-percentage (gradientSize) en of we 'both' kanten moeten vervagen.
     *
     * Zwart (rgba(0,0,0,1)) = zichtbaar
     * Transparant (rgba(0,0,0,0)) = onzichtbaar
     */
    getMaskGradient(position, gradientSize, fadeSides) {
      let direction = 'to bottom'; // default
      let isVertical = true;

      if (position === 'top') {
        // Fade verloopt verticaal van top naar bottom
        direction = 'to bottom';
        isVertical = true;
      } else if (position === 'bottom') {
        // Fade verloopt verticaal van bottom naar top
        direction = 'to top';
        isVertical = true;
      } else if (position === 'left') {
        // Fade verloopt horizontaal van left naar right
        direction = 'to right';
        isVertical = false;
      } else if (position === 'right') {
        // Fade verloopt horizontaal van right naar left
        direction = 'to left';
        isVertical = false;
      }

      // Verschil tussen 'both' kanten vervagen of maar 'single' kant
      if (fadeSides === 'both') {
        // Fade aan beide kanten
        // bijv.: 0% → transparant, gradientSize% → zwart, (100 - gradientSize)% → zwart, 100% → transparant
        return `linear-gradient(
          ${direction},
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,1) ${gradientSize}%,
          rgba(0,0,0,1) ${100 - gradientSize}%,
          rgba(0,0,0,0) 100%
        )`;
      } else {
        // Fade aan één kant
        // 'top' / 'left' => fade start bij 0% = transparant, eindigt in zwart
        // 'bottom' / 'right' => fade start bij 0% = transparant, loopt tot 100% = zwart
        if (position === 'top' || position === 'left') {
          return `linear-gradient(
            ${direction},
            rgba(0,0,0,0) 0%,            /* rand is transparant */
            rgba(0,0,0,1) ${gradientSize}%, 
            rgba(0,0,0,1) 100%           /* rest is zwart (zichtbaar) */
          )`;
        } else {
          // bottom / right
          return `linear-gradient(
            ${direction},
            rgba(0,0,0,0) 0%,           /* rand is transparant */
            rgba(0,0,0,1) ${gradientSize}%,
            rgba(0,0,0,1) 100%          /* rest is zwart (zichtbaar) */
          )`;
        }
      }
    }
  }
};
</script>