<template>
    <div class="template_selector">
        <div class="template_selector__scroll">
            <div v-for="template in templates" :key="template.id" class="template_selector__item"
                @click="toggleTemplate(template)">
                <div class="template_selector__preview">
                    <div class="template_adopt adflow__briefing_small adflow__briefing_small--story"
                        :class="{ 'template_selector__template--selected': selectedTemplates.includes(template.id) }">
                        <CanvasThumb :size="{ display_dimensions: [1080, 1920] }"
                            :canvas_id="template.preview_canvas_id" :samples="template.preview_data"
                            :brandkit="brandkit" />

                        <div v-if="selectedTemplates.includes(template.id)" class="template_selector__checkmark">
                            <i class="fa-regular fa-check"></i>
                        </div>
                    </div>
                </div>

                <div class="template_selector__name">
                    {{ template.name || 'Untitled Template' }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import api from '../../studio/store/api'
import CanvasThumb from '../../image-editor/canvas_thumb.vue'

export default {
    name: 'TemplateSelector',
    components: {
        CanvasThumb
    },
    props: {
        brandkit: {
            type: Object,
            required: true
        },
        selected: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            templates: [],
            selectedTemplates: this.selected || []
        }
    },
    async mounted() {
        this.templates = await api.get_briefings(['templates', 'image'])
    },
    watch: {
        selected(newVal) {
            this.selectedTemplates = newVal
        }
    },
    methods: {
        toggleTemplate(template) {
            const index = this.selectedTemplates.indexOf(template.id)
            if (index === -1) {
                this.selectedTemplates.push(template.id)
            } else {
                this.selectedTemplates.splice(index, 1)
            }
            this.$emit('update:templates', this.selectedTemplates)
        }
    }
}
</script>